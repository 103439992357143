import { computed, defineComponent, ref } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import { useI18N } from '@/plugins/i18n';
import SModalRight from '@/components/stemdo-components/s-modal-right/SModalRight.vue';
import CandidatesDetail from '../candidates-detail/CandidatesDetail.vue';

export default defineComponent({
  props: {
    candidates: {
      type: Array,
      required: true,
    },
  },
  components: {
    SAvatarStemdoer,
    SModalRight,
    CandidatesDetail,
  },
  setup(props) {
    const i18n = useI18N();
    const headers = computed(() => [
      { text: i18n.t('candidate.table.name'), value: 'fullName', width: '42%' },
      { text: i18n.t('candidate.table.title'), value: 'position', width: '41%' },
      { text: i18n.t('candidate.table.hourlyCost'), value: 'rate', width: '17%' },
    ]);
    const isOpenDetailModal = ref(false);
    const candidateSelected = ref(null);

    const openStemdoerDetail = stemdoer => {
      isOpenDetailModal.value = true;
      candidateSelected.value = stemdoer;
    };

    const closeStemdoerDetailModal = () => {
      document.documentElement.style.overflow = 'auto';
      isOpenDetailModal.value = false;
    };

    const candidatesWithFullNameAndRate = computed(() => {
      return (props.candidates as { name: string; surname: string; stemdoerTeams: { rate: number }[] }[]).map(candidate => ({
        ...candidate,
        fullName: `${candidate.name} ${candidate.surname}`,
        rate: candidate.stemdoerTeams.length > 0 ? candidate.stemdoerTeams[0].rate : 'N/A',
      }));
    });

    return {
      headers,
      candidates: candidatesWithFullNameAndRate,
      candidateSelected,
      isOpenDetailModal,
      openStemdoerDetail,
      closeStemdoerDetailModal,
    };
  },
});
