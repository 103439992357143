import { Authority } from '@/shared/security/authority';

import RegisterPage from '@/pages/account/register/RegisterPage.vue';
import ActivatePage from '@/pages/account/activate/ActivatePage.vue';
import ResetPasswordInitPage from '@/pages/account/reset-password-init/ResetPasswordInitPage.vue';
import ResetPasswordFinishPage from '@/pages/account/reset-password-finish/ResetPasswordFinishPage.vue';
import ChangePasswordPage from '@/pages/account/change-password/ChangePasswordPage.vue';
import LoginPage from '@/pages/account/login/LoginPage.vue';

export default [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage,
  },
  {
    path: '/account/activate',
    name: 'Activate',
    component: ActivatePage,
  },
  {
    path: '/account/reset/request',
    name: 'ResetPasswordInit',
    component: ResetPasswordInitPage,
  },
  {
    path: '/account/reset/finish',
    name: 'ResetPasswordFinish',
    component: ResetPasswordFinishPage,
  },
  {
    path: '/account/password',
    name: 'ChangePassword',
    component: ChangePasswordPage,
    meta: { authorities: [Authority.RRHH, Authority.ADMIN, Authority.CLIENT] },
  },
];
