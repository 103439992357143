import { Department, Departments, IDepartment } from './department.model';

export interface IVertical {
  id?: string;
  name?: string;
  technologyIds?: number[] | null;
  departments?: IDepartment[];
}

export class Vertical implements IVertical {
  id?: string;
  name?: string;
  technologyIds?: number[] | null;
  departments?: Departments;

  constructor(data: IVertical) {
    this.id = data?.id;
    this.name = data.name;
    this.technologyIds = data.technologyIds;
    this.departments = Departments.from(data.departments?.map(department => new Department(department)));
  }

  static empty(): Vertical {
    return new Vertical({});
  }

  static from(data: IVertical): Vertical {
    return new Vertical(data);
  }

  toIdAndDescription = (): { id: string; desc: string } => {
    return { id: this.id, desc: this.name };
  };

  getTechnologiesIds = (): number[] => {
    if (this.technologyIds) return this.technologyIds;
    if (this.departments) return this.departments.getTechnologiesIds();
    return [];
  };
}

export class Verticals extends Array<Vertical> {
  constructor(data: Vertical[]) {
    super(...data);
  }

  static empty(): Verticals {
    return new Verticals([]);
  }

  static from(data: Vertical[]): Verticals {
    if (!data) return null;
    return new Verticals(data.map(vertical => new Vertical(vertical)));
  }

  toIdAndDescription = (): { id: string; desc: string }[] => {
    return this.map(vertical => vertical.toIdAndDescription());
  };

  findById = (id: string): Vertical => {
    return this.find(vertical => vertical.id === id);
  };

  findByName = (name: string): Vertical => {
    return this.find(vertical => vertical.name === name);
  };

  getTechnologiesIds = (): number[] => {
    return this.reduce((acc, vertical) => {
      if (vertical.technologyIds) return [...acc, ...vertical.technologyIds];
      if (vertical.departments) return [...acc, ...vertical.departments.getTechnologiesIds()];
    }, []);
  };

  prioritizeVerticalById = (verticalId: string): void => {
    const index = this.findIndex(vertical => vertical.id === verticalId);
    if (index > -1) {
      const [vertical] = this.splice(index, 1);
      this.unshift(vertical);
    }
  };
}
