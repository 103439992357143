import { defineComponent, inject, ref, onMounted, computed, watch } from 'vue';
import FilterTagList from '../filter-tag-list/FilterTagList.vue';
import MoreFilters from '../more-filters/MoreFilters.vue';
import TechnologyService from '@/sections/admin/entities/technology/technology.service';
import SoftSkillService from '@/sections/admin/entities/soft-skill/soft-skill.service';
import { useStore } from '@/plugins/vuex';
import { IAtocompleteMultipleState } from '@/components/stemdo-components/s-autocomplete-multiple/sAutocompleteMultiple';
import { FilterFields } from '../model/Filters';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import { IRate } from '@/shared/model/rate.model';
import VerticalService from '@/sections/admin/entities/vertical/vertical.service';
import { IAtocompleteState } from '@/components/stemdo-components/s-autocomplete/sAutocomplete';
import { Vertical, Verticals } from '@/shared/model/vertical.model';
import ClientSelector from '../client-selector/ClientSelector.vue';
import { IClient } from '@/shared/model/client.model';
import { Technologies } from '@/shared/model/technology.model';
import {
  fromVerticalsToAtocompleteMultipleRating,
  fromVerticalToAtocompleteMultipleRating
} from '@/components/stemdo-components/s-autocomplete-multiple-rating/autocompleteMultipleRating.model';
import { IAtocompleteMultipleRatingState } from '@/components/stemdo-components/s-autocomplete-multiple-rating/sAutocompleteMultipleRating';

const MORE_FILTERS_ICON_SRC = '/content/svgs/more-filters.svg';
export default defineComponent({
  components: {
    FilterTagList,
    MoreFilters,
    ClientSelector
  },
  props: {
    stemdoers: {
      type: Array as () => IStemdoer[],
      required: true
    },
    rates: {
      type: Array as () => IRate[],
      required: true
    },
    defaultClientSelector: {
      type: Object as () => IClient
    }
  },
  setup() {
    const store = useStore();
    const verticals = ref<Verticals>(Verticals.empty());
    const technologyService: TechnologyService = inject('technologyService');
    const softSkillService: SoftSkillService = inject('softSkillService');
    const verticalService: VerticalService = inject('verticalService');
    const openMoreFilters = ref(false);
    const selectedVertical = ref<Vertical>(Vertical.empty());
    const verticalsItems = ref<IAtocompleteMultipleState[]>([]);
    const technologiesField = FilterFields.Technology;
    const defaultSelectedVertical = ref<IAtocompleteState>(undefined);
    const competenciesField = FilterFields.Competency;
    const technologies = ref<Technologies>(Technologies.empty());
    const technologyItemsByVertical = ref<IAtocompleteMultipleRatingState[]>([]);
    const technologyItems = ref<IAtocompleteMultipleRatingState[]>([]);
    const compFilterKey = ref(0);
    const softSkillsItems = ref<IAtocompleteMultipleState[]>([]);
    const isManager = computed(() => store.getters.account?.authorities.includes('ROLE_GESTOR') ?? false);
    const totalFilters = computed(() => {
      return store.getters['filterStore/totalFilters'];
    });
    const totalTags = computed(() => {
      const tags = store.getters['filterStore/filters'];
      return tags.filter(tags => tags.field !== FilterFields.Available && tags.field !== FilterFields.Technology).length;
    });

    watch(
      () => selectedVertical.value,
      (vertical: Vertical) => {
        technologyItemsByVertical.value = fromVerticalToAtocompleteMultipleRating(vertical, technologies.value);
        verticals.value.prioritizeVerticalById(vertical.id);
        technologyItems.value = fromVerticalsToAtocompleteMultipleRating(verticals.value, technologies.value);
      },
      { deep: true }
    );

    onMounted(async () => {
      loadSoftSkills();
      technologies.value = await technologyService.retrieve();
      verticals.value = await verticalService.retrieve();
      verticalsItems.value = verticals.value.toIdAndDescription();

      selectedVertical.value = getDefaultVertical();
      defaultSelectedVertical.value = { id: selectedVertical.value.id, desc: selectedVertical.value.name };
    });

    const getDefaultVertical = (): Vertical => {
      const storedVertical = store.getters['filterStore/getAutoCompleteState'](technologiesField);
      if (storedVertical) return verticals.value.findById(storedVertical.id);
      return verticals.value.findByName('Cloud');
    };

    const clickVerticalFilter = (field: FilterFields, newSelectedItem: IAtocompleteMultipleState, temp?: boolean) => {
      selectedVertical.value = verticals.value.find(vertical => vertical.id === newSelectedItem.id);
      const payload = { field, newSelectedItem };
      if (!temp) {
        store.commit('filterStore/updateAutoCompleteState', payload);
      }
      if (temp) store.commit('filterStore/updateAutoCompleteTempState', payload);
    };

    const clickCompFilter = (field: FilterFields, newSelectedItem: IAtocompleteMultipleRatingState) => {
      const payload = { field, newSelectedItem };
      store.commit('filterStore/updateAutoCompleteMultipleRatingState', payload);
    };

    const loadSoftSkills = async () => {
      softSkillService.retrieve().then(res => {
        const softSkills = res.data;
        softSkills.forEach(skill => {
          softSkillsItems.value.push({ id: skill.id, desc: 'stemdoPlatformApp.softSkill.list.' + skill.externalId });
        });
      });
    };

    const openMoreFiltersModal = () => {
      store.commit('filterStore/resetTempFilters');
      openMoreFilters.value = true;
    };

    const closeMoreFiltersModal = () => {
      openMoreFilters.value = false;

      const el = document.getElementById('modal-centered-content');
      el.scrollIntoView();

      // Update key of comp filter to force re-render and update input label text
      compFilterKey.value += 1;

      selectedVertical.value = verticals.value.find(v => v.id === store.getters['filterStore/getAutoCompleteState'](technologiesField).id);
    };

    const applyFilters = () => {
      store.commit('filterStore/commitTempFilters');
      closeMoreFiltersModal();
    };

    return {
      moreFiltersIconSrc: MORE_FILTERS_ICON_SRC,
      openMoreFilters,
      technologiesField,
      competenciesField,
      verticalsItems,
      defaultSelectedVertical,
      technologyItems,
      technologyItemsByVertical,
      softSkillsItems,
      totalFilters,
      totalTags,
      isManager,
      compFilterKey,
      clickVerticalFilter,
      clickCompFilter,
      openMoreFiltersModal,
      closeMoreFiltersModal,
      applyFilters
    };
  }
});
