import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import Vue2Filters from 'vue2-filters';
import { BootstrapVue, ToastPlugin } from 'bootstrap-vue';
import router from './router';
import vuetify from '@/plugins/vuetify';
import store from '@/plugins/vuex';
import i18n from '@/plugins/i18n';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import SRangeSliderStepsRate from '@/components/stemdo-components/s-range-slider-steps-rate/SRangeSliderStepsRate.vue';
import SAutocomplete from '@/components/stemdo-components/s-autocomplete/SAutocomplete.vue';
import SAutocompleteMultiple from '@/components/stemdo-components/s-autocomplete-multiple/SAutocompleteMultiple.vue';
import SAutocompleteMultipleRating from '@/components/stemdo-components/s-autocomplete-multiple-rating/SAutocompleteMultipleRating.vue';
import SCardStemdoer from '@/components/stemdo-components/s-card-stemdoer/SCardStemdoer.vue';
import MetricsService from './sections/admin/metrics/metrics.service';
import LogsService from './sections/admin/logs/logs.service';
import ActivateService from './sections/account/activate/activate.service';
import RegisterService from './sections/account/register/register.service';
import UserManagementService from './sections/admin/user-management/user-management.service';
import AccountService from './sections/account/account.service';
import AlertService from './shared/alert/alert.service';
import ClientService from './sections/admin/entities/client/client.service';
import StemdoerService from './sections/admin/entities/stemdoer/stemdoer.service';
import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';
import ResourceService from './shared/ResourceService.service';
import CertificationService from './sections/admin/entities/certification/certification.service';
import TeamRequestService from './sections/admin/entities/team-request/team-request.service';
import SoftSkillService from './sections/admin/entities/soft-skill/soft-skill.service';
import ClientRateService from './sections/admin/entities/client-rate/client-rate.service';
import NoResultFiltersReportService from './sections/admin/entities/no-result-filters-report/no-result-filters-report.service';
import configAxios from './shared/config/config-axios';
import { Authority } from '@/shared/security/authority';
import accountPages from '@/router/accountPages';
import localStorageFacade, { StorageKey } from './shared/local-store-facade/LocalStoreFacade';
import TechnologyService from './sections/admin/entities/technology/technology.service';
import VerticalService from './sections/admin/entities/vertical/vertical.service';
import AuthToken from './shared/security/AuthToken';
import ClientDepartmentService from './sections/admin/entities/client-department/client-department.service';

Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
configAxios();
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.use(BootstrapVue);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('SButton', SButton);
Vue.component('SAutocomplete', SAutocomplete);
Vue.component('SAutocompleteMultiple', SAutocompleteMultiple);
Vue.component('SAutocompleteMultipleRating', SAutocompleteMultipleRating);
Vue.component('SModalCentered', SModalCentered);
Vue.component('SRangeSliderStepsRate', SRangeSliderStepsRate);
Vue.component('SCardStemdoer', SCardStemdoer);

router.beforeResolve(async (to, from, next) => {
  if (to.name === 'ServerError' || to.name === 'NotFoundError' || to.name === 'Forbidden') {
    next();
    return;
  }

  const isAuthenticated = store.getters.authenticated;
  const authToken = localStorageFacade.get(StorageKey.AUTH_TOKEN);
  if (!authToken) {
    // If the page not require authentication, continue
    if (accountPages.find(page => page.name === to.name) || to.name === 'Home') {
      next();
      return;
    }
    // Otherwise, store where the user wants to go and redirect to login page
    localStorageFacade.set(StorageKey.REQUESTED_URL, to.fullPath);
    next({ name: 'login' });
  } else {
    if (to.meta.authorities?.length !== 0 && !AuthToken.hasAnyAuthority(to.meta.authorities)) {
      next({ name: 'Forbidden' });
    }

    if (isAuthenticated) {
      if (to.name === 'login') next({ name: 'Home' });
      else next();
    } else if (!isAuthenticated) {
      try {
        const accountResponse = await accountService.retrieveAccount();
        const account = accountResponse.data;

        store.commit('authenticate', account);
        const isClient = account.authorities.find((authority: any) => authority === Authority.CLIENT);
        if (isClient) await accountService.retrieveClientDetails(account.email);

        if (store.getters.currentLanguage !== account.langKey) {
          await store.commit('currentLanguage', account.langKey);
        }

        if (localStorageFacade.get(StorageKey.REQUESTED_URL)) {
          next({ path: localStorageFacade.get(StorageKey.REQUESTED_URL) });
          localStorageFacade.remove(StorageKey.REQUESTED_URL);
        } else if (to.name === 'login') next({ name: 'Home' });
        else next();
      } catch (error) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          localStorageFacade.set(StorageKey.REQUESTED_URL, to.fullPath);
          next({ name: 'login' });
          return;
        }
        localStorageFacade.set(StorageKey.REQUESTED_URL, to.fullPath);
        next({ name: 'ServerError' });
      }
    }
  }

  next();
});

const translationService = new TranslationService(store, i18n);
const clientService = new ClientService();
const accountService = new AccountService(store, translationService, clientService);

const vue = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    activateService: () => new ActivateService(),
    registerService: new RegisterService(),
    userManagementService: () => new UserManagementService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),
    clientService: () => clientService,
    stemdoerService: new StemdoerService(),
    teamRequestService: new TeamRequestService(),
    resourceService: new ResourceService(),
    certificationService: new CertificationService(),
    softSkillService: new SoftSkillService(),
    translationService: translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
    clientRateService: new ClientRateService(),
    noResultFiltersReportService: new NoResultFiltersReportService(),
    technologyService: new TechnologyService(),
    verticalService: new VerticalService(),
    clientDepartmentService: () => new ClientDepartmentService(),
  },
  i18n,
  store,
  vuetify,
});
