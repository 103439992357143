import { helpers, maxLength, minLength, required, sameAs, email } from 'vuelidate/lib/validators';
import RegisterService from '@/sections/account/register/register.service';
import { EMAIL_ALREADY_USED_TYPE, LOGIN_ALREADY_USED_TYPE, EMAIL_NO_EXIST_STEMDOERS } from '@/constants';
import { IClient, Client } from '@/shared/model/client.model';
import { ref, reactive, onMounted, computed, toRefs, watch, inject } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SPopUp from '@/components/stemdo-components/s-pop-up/SPopUp.vue';
import router from '@/router';
import { validationMixin } from 'vuelidate';
import { useVuelidate } from '@vuelidate/core';
import ClientDepartmentService from '@/sections/admin/entities/client-department/client-department.service';

const forbiddenDomains = [
  '@stemdo',
  '@gmail',
  '@yahoo',
  '@hotmail',
  '@outlook',
  '@live',
  '@aol',
  '@icloud',
  '@mail',
  '@zoho',
  '@yandex',
  '@protonmail',
  '@gmx',
  '@me',
  '@msn',
  '@rocketmail',
  '@inbox',
  '@fastmail',
  '@rediffmail',
  '@tutanota',
  '@hushmail',
  '@lycos',
  '@mac',
  '@comcast',
  '@att',
  '@verizon',
  '@btinternet',
  '@ntlworld',
  '@virginmedia',
  '@sbcglobal',
  '@earthlink',
  '@optonline',
  '@cox',
  '@shaw',
  '@sympatico',
  '@telus',
  '@qq',
  '@163',
  '@126',
  '@sina',
  '@yeah'
];

export default {
  mixins: [validationMixin],
  validations() {
    const isForbiddenDomain = (value: string) => {
      return !forbiddenDomains.some(domain => value.includes(domain));
    };

    return {
      registerAccount: {
        login: {
          // required,
          minLength: minLength(1),
          maxLength: maxLength(50),
          pattern: helpers.regex('alpha', /^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$/)
        },
        email: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(254),
          email,
          notForbiddenDomain: isForbiddenDomain
        },
        first_name: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(50),
          pattern: helpers.regex('alpha', /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/)
        },
        last_name: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(50),
          pattern: helpers.regex('alpha', /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+( [a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+)*$/)
        },
        phone: {
          required,
          pattern: helpers.regex('phone', /^\+?([0-9]{2})?[679]{1}\d{8}$/)
        },
        department_id: {
          required
        }
      },
      termsAccepted: {
        required
      }
    };
  },

  components: {
    SButton,
    SPopUp
  },
  setup(_, { emit }) {
    const registerService: RegisterService = inject('registerService');
    const clientDepartmentService = new ClientDepartmentService();
    const clientDepartments = ref([]);

    onMounted(async () => {
      try {
        const response = await clientDepartmentService.retrieve();
        clientDepartments.value = response;
      } catch (error) {
        console.error('Error retrieving client departments:', error);
      }
    });

    const registerAccount = ref({
      login: '',
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      department_id: '',
      langKey: 'es'
    });

    const isClient = ref(false);
    const checkIfClient = ref(true);
    const termsAccepted = ref(null);
    const client = reactive(new Client());
    const error = ref(null);
    const errorUserExists = ref(null);
    const errorEmailExists = ref(null);
    const errorEmailNoExists = ref(null);
    const success = ref(false);
    const showValidationText = ref(false);
    const v$ = useVuelidate();
    const isFormInvalid = ref<boolean | null>(null);

    const checkNameAndSurname = () => {
      if (registerAccount.value.last_name !== '' && registerAccount.value.first_name !== '') {
        checkIfClient.value = false;
      } else {
        checkIfClient.value = true;
      }
    };

    const gotoLogin = () => {
      router.push({
        name: 'login'
      });
    };

    const bytesToBase64 = bytes => {
      const binString = String.fromCodePoint(...bytes);
      return btoa(binString);
    };

    watch([registerAccount, termsAccepted], () => {
      isFormInvalid.value = null;
    });

    const validateForm = () => {
      v$.value.$touch();
      isFormInvalid.value = v$.value.registerAccount.$invalid || v$.value.termsAccepted.$invalid;
      if (termsAccepted.value === null) {
        termsAccepted.value = false;
      }
      if (!isFormInvalid.value && termsAccepted.value) {
        register();
      }
    };

    const register = async () => {
      error.value = null;
      errorUserExists.value = null;
      errorEmailExists.value = null;
      errorEmailNoExists.value = null;
      registerAccount.value.login = registerAccount.value.email;
      client.email = registerAccount.value.email;
      client.phone = registerAccount.value.phone;
      client.name = registerAccount.value.first_name;
      client.surname = registerAccount.value.last_name;
      client.departmentId = registerAccount.value.department_id;

      try {
        await registerService.processRegistration(registerAccount.value, client);
        success.value = true;
        emit('sms', {
          success: success.value,
          error: error.value,
          errorUserExists: errorUserExists.value,
          errorEmailExists: errorEmailExists.value,
          errorEmailNoExists: errorEmailNoExists.value
        });
      } catch (error) {
        success.value = null;
        if (error.response.status === 400 && error.response.data.type === LOGIN_ALREADY_USED_TYPE) {
          errorUserExists.value = 'ERROR';
        } else if (error.response.status === 400 && error.response.data.type === EMAIL_ALREADY_USED_TYPE) {
          errorEmailExists.value = 'ERROR';
        } else if (error.response.status === 400 && error.response.data.type === EMAIL_NO_EXIST_STEMDOERS) {
          errorEmailNoExists.value = 'ERROR';
        } else {
          error.value = 'ERROR';
        }
        emit('sms', {
          success: success.value,
          error: error.value,
          errorUserExists: errorUserExists.value,
          errorEmailExists: errorEmailExists.value,
          errorEmailNoExists: errorEmailNoExists.value
        });
      }
    };

    return {
      registerAccount,
      error,
      errorUserExists,
      errorEmailExists,
      errorEmailNoExists,
      success,
      showValidationText,
      isClient,
      checkIfClient,
      termsAccepted,
      isFormInvalid,
      clientDepartments,
      ...toRefs(client),
      validateForm,
      bytesToBase64,
      gotoLogin,
      checkNameAndSurname,
      register,
      v$
    };
  }
};
