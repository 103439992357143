<template>
  <TeamRequestDetail :teamRequestId="teamRequestId" />
</template>

<script setup>
import TeamRequestDetail from '@/sections/shared/team-request-detail/TeamRequestDetail.vue';
import { useRouter } from '@/plugins/router';

const router = useRouter();
const teamRequestId = router.currentRoute.params['id'].toString();
</script>
