import axios from 'axios';
import { helpers, maxLength, minLength, required, sameAs } from 'vuelidate/lib/validators';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SPopUp from '@/components/stemdo-components/s-pop-up/SPopUp.vue';
import { computed, ref } from 'vue';
import router from '@/router';
import useVuelidate from '@vuelidate/core';
import { validationMixin } from 'vuelidate';
import { onMounted, watch } from 'vue';

export default {
  mixins: [validationMixin],

  validations: {
    resetAccount: {
      newPassword: {
        pattern: helpers.regex('alpha', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
        required,
        minLength: minLength(8),
        maxLength: maxLength(254),
        number: helpers.regex('number', /\d/),
        lowerCase: helpers.regex('lowerCase', /[a-z]/),
        upperCase: helpers.regex('upperCase', /[A-Z]/)
      },
      confirmPassword: {
        sameAsPassword: sameAs(vm => {
          return vm.newPassword;
        })
      }
    }
  },

  components: {
    SButton,
    SPopUp
  },

  setup() {
    const doNotMatch = ref(null);
    const success = ref(false);
    const error = ref(false);
    const keyMissing = ref(false);
    const key = ref(null);
    const showValidationText = ref(false);
    const isFormInvalid = ref<boolean | null>(null);
    const resetAccount = ref({
      newPassword: null,
      confirmPassword: null
    });

    const v$ = useVuelidate();

    watch([() => resetAccount.value.newPassword], () => {
      isFormInvalid.value = null;
    });

    onMounted(() => {
      if (router?.currentRoute?.query?.key !== undefined) {
        key.value = router.currentRoute.query.key;
      }
      keyMissing.value = !key.value;
    });

    const gotoLogin = () => {
      router.push('/login');
    };

    const validateForm = () => {
      v$.value.$touch();
      isFormInvalid.value = v$.value.resetAccount && v$.value.resetAccount.$invalid;
      if (!isFormInvalid.value) {
        finishReset();
      }
    };

    const finishReset = (): void => {
      doNotMatch.value = false;
      success.value = false;
      error.value = false;
      if (resetAccount.value.newPassword !== resetAccount.value.confirmPassword) {
        doNotMatch.value = 'ERROR';
      } else {
        axios
          .post('api/account/reset-password/finish', { key: key.value, newPassword: resetAccount.value.newPassword })
          .then(() => {
            success.value = true;
          })
          .catch(() => {
            success.value = false;
            error.value = true;
          });
      }
    };

    const getMessage = computed(() => {
      if (keyMissing.value) {
        return 'reset.finish.messages.keymissing';
      }
      if (error.value) {
        return 'reset.finish.messages.expire';
      }
      if (success.value) {
        return 'reset.finish.messages.success';
      }
      return '';
    });

    const handleClose = () => {
      keyMissing.value = false;
      error.value = false;
      success.value = false;
    };

    return {
      isFormInvalid,
      doNotMatch,
      success,
      error,
      keyMissing,
      resetAccount,
      showValidationText,
      getMessage,
      validateForm,
      gotoLogin,
      handleClose
    };
  }
};
