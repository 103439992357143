/* eslint-disable prettier/prettier */
import { PropType, computed, defineComponent, inject, ref } from 'vue';
import { useRouter } from '@/plugins/router';
import { useStore } from '@/plugins/vuex';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { ButtonGroupModals } from '../../../buttonsGroup';

type StyleProps = {
  bodyPaddingY: number;
  fullWidthFooter?: boolean;
};

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '500px',
    },
    onClose: {
      type: Function,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    teamRequestId: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const router = useRouter();
    const store = useStore();
    const teamRequestName = ref('');
    const teamRequestNameRef = ref(null);
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };

    const defaultProps: StyleProps = {
      bodyPaddingY: 2,
      fullWidthFooter: false,
    };
    const newProps: StyleProps = {
      ...defaultProps,
      ...customModalProps,
    };

    const close = () => {
      props.onClose(ButtonGroupModals.CHECK_CART_SAVE_TR);
    };

    const clickOutside = () => {
      if (!props.persistent) {
        close();
      }
      focusVDialogComponent();
    };

    const keyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };

    const focusVDialogComponent = () => {
      const dialogComponent = document.getElementsByClassName('v-dialog-component')[0] as HTMLElement;
      dialogComponent?.focus();
    };

    const saveTeamRequestWithStemdoersInStore = () => {
      if (teamRequestNameRef.value) {
        teamRequestName.value = teamRequestNameRef.value.value;
      }

      const stemdoers = computed(() => store.getters['cartStore/cartItems']);
      const client = computed(() => store.getters['client']);

      const teamRequestToSave = {
        name: teamRequestName.value,
        stemdoers: stemdoers.value,
        client: client.value,
        status: RequestStatus.SAVED,
      };

      teamRequestService
        .create(teamRequestToSave)
        .then(() => {
          gotoExplore();
        })
        .catch(error => {
          console.error(error);
        });
    };

    const gotoExplore = () => {
      router.push({
        name: 'exploreClient',
        query: { tr: props.teamRequestId },
      });
    };

    return {
      teamRequestName,
      teamRequestNameRef,
      newProps,
      close,
      clickOutside,
      keyDown,
      gotoExplore,
      saveTeamRequestWithStemdoersInStore,
    };
  },
});
