var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _vm.isUserClient
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", [
                _c("div", { staticClass: "box" }, [
                  _c("span", {
                    staticClass:
                      "text-body-2-medium text--text text--lighten-1 mb-1",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("checkout.overview.hourlyCost")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-h2 text--text text--base" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.hourlyCost
                            ? _vm.$n(
                                _vm.hourlyCost / _vm.numStemdoers,
                                "decimal"
                              ) + "€/h"
                            : "---"
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", [
                _c("div", { staticClass: "box" }, [
                  _c("span", {
                    staticClass:
                      "text-body-2-medium text--text text--lighten-1 mb-1",
                    domProps: {
                      textContent: _vm._s(_vm.$t("checkout.overview.client")),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-h4 text--text text--base" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.client.name +
                            " " +
                            _vm.client.surname +
                            (_vm.client.company
                              ? " - " + _vm.client.company.name
                              : "")
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "mr-4" }, [
            _c("div", { staticClass: "box" }, [
              _c("span", {
                staticClass: "text-body-2-medium text--text text--lighten-1",
                domProps: {
                  textContent: _vm._s(_vm.$t("checkout.overview.dailyCost")),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-h3 text--text text--base" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.hourlyCost
                        ? _vm.$n(_vm.hourlyCost * 8, "decimal") + "€"
                        : "---"
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("div", { staticClass: "box" }, [
              _c("span", {
                staticClass: "text-body-2-medium text--text text--lighten-1",
                domProps: {
                  textContent: _vm._s(_vm.$t("checkout.overview.monthlyCost")),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-h3 text--text text--base" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.hourlyCost
                        ? _vm.$n(_vm.hourlyCost * 8 * 20, "decimal") + "€"
                        : "---"
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }