import { defineComponent, inject, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { ButtonGroupModals } from '../../../buttonsGroup';
import { TeamRequest } from '@/shared/model/team-request.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true,
    },
    refreshTeamRequest: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onSuccess: {
      type: Function,
      required: true,
    },
    onError: {
      type: Function,
      required: true,
    },
  },
  components: {
    SButton,
    SModalCentered,
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };

    const handleClose = () => {
      props.onClose(ButtonGroupModals.ACCEPT_TR);
    };

    const acceptTeamRequest = () => {
      if (props.teamRequest.status === RequestStatus.VALIDATED) {
        teamRequestService
          .changeStatus(props.teamRequest.id, RequestStatus.ON_GOING)
          .then(() => {
            props.onClose(ButtonGroupModals.ACCEPT_TR);
            props.refreshTeamRequest();
            props.onSuccess('teamRequestDetail.popUp.teamRequestAccepted');
          })
          .catch(() => {
            props.onError();
          });
      }
    };

    return {
      acceptTeamRequest,
      customModalProps,
      handleClose,
    };
  },
});
