import { IVertical, Vertical } from '@/shared/model/vertical.model';
import { ITeamRequest } from '@/shared/model/team-request.model';

export interface ICertification {
  id?: string;
  name?: string;
  urlCertification?: string;
  validation?: boolean | null;
  vertical?: IVertical | null;
  teamRequests?: ITeamRequest[] | null;
}

export class Certification implements ICertification {
  constructor(
    public id?: string,
    public name?: string,
    public urlCertification?: string,
    public validation?: boolean | null,
    public vertical?: Vertical | null,
    public teamRequests?: ITeamRequest[] | null
  ) {}

  static toCertification(json: any): Certification {
    if (json.vertical == null || json.vertical == undefined) {
      return new Certification(json.id, json.name, json.urlCertification, json.validation, null);
    }
    return new Certification(json.id, json.name, json.urlCertification, json.validation, Vertical.from(json.vertical));
  }

  static toCertifications(json: any[]): Certification[] {
    return json.map(Certification.toCertification);
  }

  public static getCertificationImgUrlByName(certificationName: string): string {
    switch (certificationName) {
      case 'AZ-204 Azure Developer Associate':
        return '/content/images/AZ204.png';
      case 'PL-100 Power Platform App Maker Associate':
        return '/content/images/PL100.png';
      case 'AWS Cloud Practitioner':
        return '/content/images/aws-certified-cloud-practitioner-logo.png';
      case 'AZ900 Azure Fundamentals':
        return '/content/svgs/az900-azure-fundamentals.svg';
      case 'AI900 Azure AI Fundamentals':
        return '/content/images/ai900azureaifundamentals.png';
      case 'AZ500 Azure Security Engineer Associate':
        return '/content/svgs/az500-azure-security-engineer-associate.svg';
      case 'Appian Associate Developer':
        return '/content/images/appian-certified-associate-logo.png';
      case 'PL-300 Microsoft PowerBI Data Analyst Associate':
        return '/content/images/pl-300microsoftpowerbidataanalystassociate.png';
      case 'Google Professional Data Engineer':
        return '/content/images/googleprofessionaldataengineer.png';
      default:
        return '';
    }
  }
}
