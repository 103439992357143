var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "p-0 m-0", staticStyle: { "min-width": "100% !important" } },
    [
      _c(
        "v-row",
        { staticClass: "no-wrap filter-clean", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c(
                "v-slide-group",
                { attrs: { "show-arrows": "" } },
                _vm._l(_vm.tags, function (tag) {
                  return _c(
                    "v-slide-item",
                    { key: `${tag.getId()}`, staticClass: "text-center mr-2" },
                    [_c("FilterTag", { attrs: { tag: tag } })],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass:
                "clear-button text--text text--lighten-1 font-weight-bold align-self-center",
              attrs: { cols: "1" },
              on: { click: _vm.handleClear },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mr-auto",
                  staticStyle: { width: "min-content" },
                },
                [_vm._v(_vm._s(_vm.$t("explore.filter.clearFilters")))]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }