var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.candidateSelected
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "px-6 pt-4",
                    attrs: { id: "stemdoer-profile" },
                  },
                  [
                    _c("SAvatarStemdoer", {
                      staticStyle: {
                        height: "55px !important",
                        width: "55px !important",
                      },
                      attrs: {
                        stemdoId: _vm.candidateSelected.stemdoId,
                        size: "55px",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "pl-3",
                        attrs: { id: "stemdoer-description" },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "my-1 pl-2 text-body-1 candidate-name",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.candidateSelected.name +
                                    " " +
                                    _vm.candidateSelected.surname
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "pb-1 pl-2 position text-body-1-bold candidate-title",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.candidateSelected.position) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "candidate-list-detail" } },
        [
          _c(
            "v-col",
            { staticClass: "sections py-4 px-4 my-4", attrs: { cols: "12" } },
            [
              _c("p", {
                staticClass: "sections-title text-subtitle-4 pb-2",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("candidate.cadidateModalDetail.actualTeam")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "sections-description text-body-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("candidate.cadidateModalDetail.actualTeamNotAsigned")
                  ),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "sections py-4 px-4 my-4", attrs: { cols: "12" } },
            [
              _c("p", {
                staticClass: "sections-title text-subtitle-4 pb-2",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("candidate.cadidateModalDetail.actualTeamDate")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "sections-description text-body-1" }, [
                _vm._v(
                  _vm._s(
                    _vm.getInitDateFromStemdoerTeams(
                      _vm.candidateSelected.stemdoerTeams
                    )
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [_c("p")])
  },
]
render._withStripped = true

export { render, staticRenderFns }