import Component from 'vue-class-component';
import { Vue, Inject } from 'vue-property-decorator';
import ActivateService from './activate.service';

@Component
export default class Activate extends Vue {
  @Inject('activateService')
  private activateService: () => ActivateService;
  success = false;
  error = false;

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.query.key) {
        vm.init(to.query.key);
      }
    });
  }

  public init(key: string): void {
    this.activateService()
      .activateAccount(key)
      .then(
        () => {
          this.success = true;
          this.error = false;
        },
        () => {
          this.error = true;
          this.success = false;
        }
      );
  }

  public openLogin(): void {}
}
