<template>
  <div class="h-100" style="background-color: white">
    <v-img :src="`/content/svgs/coming-soon/${currentLanguage}/teams.svg`"></v-img>
  </div>
</template>

<script setup>
import { useStore } from '@/plugins/vuex';
import { computed } from 'vue';
const store = useStore();
const currentLanguage = computed(() => store.getters.currentLanguage);
</script>
