var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("SModalCentered", {
        attrs: {
          title: _vm.$t(
            "teamRequestDetail.modal.client.acceptTeamRequestOnlyAvailable.title"
          ),
          isOpen: _vm.isOpen,
          closeModal: _vm.handleClose,
          customProps: _vm.customModalProps,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-body-1 text--text text--lighten-1" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "teamRequestDetail.modal.client.acceptTeamRequestOnlyAvailable.info"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "v-container",
                  { staticClass: "p-0" },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pr-2", attrs: { cols: "6" } },
                          [
                            _c("SButton", {
                              attrs: {
                                action: _vm.handleClose,
                                btnStyle: "secondary",
                                sText: _vm.$t(
                                  "teamRequestDetail.modal.client.acceptTeamRequestOnlyAvailable.secondaryButtonText"
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "pl-2", attrs: { cols: "6" } },
                          [
                            _c("SButton", {
                              attrs: {
                                action:
                                  _vm.acceptTeamRequestWithoutStemdoerBlocked,
                                btnStyle: "primary",
                                sText: _vm.$t(
                                  "teamRequestDetail.modal.client.acceptTeamRequestOnlyAvailable.primaryButtonText"
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }