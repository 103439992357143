var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.displayTag
    ? _c(
        "div",
        { staticClass: "d-flex px-2 py-1 ma-0 container" },
        [
          _c("v-img", {
            staticClass: "mr-2",
            attrs: {
              "max-width": 20,
              src: "/content/svgs/star-fill-yellow.svg",
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "text-body-3",
            domProps: { textContent: _vm._s(_vm.$t("sCardStemdoer.interest")) },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }