<template>
  <div class="d-flex align-center h-100">
    <div class="badge-wrapper">
      <span class="badge text-body-4-bold">SOON</span>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {};
  }
};
</script>

<style scoped>
/* Style for test badge */
.badge-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 21px;
  max-width: 45px;
  min-width: 45px;
  background-color: var(--v-secondary-lighten1);
}

.badge {
  color: var(--v-secondary-base);
}
</style>
