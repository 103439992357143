import { Vertical, Verticals } from '@/shared/model/vertical.model';
import { Technologies, Technology } from '@/shared/model/technology.model';
import { IAtocompleteMultipleRatingState } from './sAutocompleteMultipleRating';
import { useI18N } from '@/plugins/i18n';
import { Departments } from '@/shared/model/department.model';

export const fromVerticalToAtocompleteMultipleRating = (
  vertical: Vertical,
  technologyMaster: Technologies
): IAtocompleteMultipleRatingState[] => {
  let autocompleteMultipleRating: IAtocompleteMultipleRatingState[] = [];
  if (vertical.technologyIds) {
    autocompleteMultipleRating = vertical.technologyIds.map(technologyId => {
      const technology = technologyMaster.findById(technologyId);
      if (technology) return fromTechnologyToAtocompleteMultipleRating(technology);
    });
  }
  if (vertical.departments) {
    autocompleteMultipleRating = fromDepartmentsToAtocompleteMultipleRating(vertical.departments, technologyMaster);
  }

  return autocompleteMultipleRating;
};

export const fromVerticalsToAtocompleteMultipleRating = (
  verticals: Verticals,
  technologyMaster: Technologies
): IAtocompleteMultipleRatingState[] => {
  let autocompleteMultipleRating: IAtocompleteMultipleRatingState[] = [];

  const commonTechnologyIds = getCommonTechnologyIds(verticals.getTechnologiesIds());

  verticals.forEach((vertical, index) => {
    if (index === 1) {
      if (commonTechnologyIds.length > 0) {
        autocompleteMultipleRating = autocompleteMultipleRating.concat(
          toAutocompleteMultipleRatingWithHeader('Common', commonTechnologyIds, technologyMaster)
        );
      }
    }
    if (vertical.technologyIds) {
      autocompleteMultipleRating = autocompleteMultipleRating.concat(
        toAutocompleteMultipleRatingWithHeader(vertical.name, vertical.technologyIds, technologyMaster)
      );
    }
    if (vertical.departments) {
      vertical.departments.forEach(department => {
        autocompleteMultipleRating = autocompleteMultipleRating.concat(
          toAutocompleteMultipleRatingWithHeader(department.name, department.technologyIds, technologyMaster)
        );
      });
    }
  });
  return autocompleteMultipleRating;
};

const fromDepartmentsToAtocompleteMultipleRating = (
  departments: Departments,
  technologyMaster: Technologies
): IAtocompleteMultipleRatingState[] => {
  let autocompleteMultipleRating: IAtocompleteMultipleRatingState[] = [];
  const commonTechnologyIds = getCommonTechnologyIds(departments.getTechnologiesIds());
  if (commonTechnologyIds.length > 0) {
    autocompleteMultipleRating = autocompleteMultipleRating.concat(
      toAutocompleteMultipleRatingWithHeader('Common', commonTechnologyIds, technologyMaster)
    );
  }
  departments.forEach(department => {
    autocompleteMultipleRating = autocompleteMultipleRating.concat(
      toAutocompleteMultipleRatingWithHeader(department.name, department.technologyIds, technologyMaster)
    );
  });
  return autocompleteMultipleRating;
};

const getCommonTechnologyIds = (technologiesIds: number[]): number[] => {
  const technologyCount: { [key: string]: number } = {};
  technologiesIds.forEach(technologyId => {
    if (!technologyCount[technologyId]) {
      technologyCount[technologyId] = 0;
    }
    technologyCount[technologyId]++;
  });

  return Object.keys(technologyCount)
    .filter(technologyId => technologyCount[technologyId] > 1)
    .map(technologyId => parseInt(technologyId, 10));
};

const toAutocompleteMultipleRatingWithHeader = (
  header: string,
  technologiesIds: number[],
  technologyMaster: Technologies
): IAtocompleteMultipleRatingState[] => {
  const autocompleteMultipleRating: IAtocompleteMultipleRatingState[] = [];
  //@ts-ignore
  autocompleteMultipleRating.push({ header: header });
  technologiesIds.forEach(technologyId => {
    const technology = technologyMaster.findById(technologyId);
    autocompleteMultipleRating.push(fromTechnologyToAtocompleteMultipleRating(technology));
  });
  return autocompleteMultipleRating;
};

const fromTechnologyToAtocompleteMultipleRating = (technology: Technology): IAtocompleteMultipleRatingState => {
  if (!technology) return null;
  const i18n = useI18N();
  return { id: technology.id, desc: i18n.t(`technologyMaster.list.${technology.id}`).toString(), rating: 0 };
};
