var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8" }, [
        _c("h1", {
          domProps: { textContent: _vm._s(_vm.$t("activate.title")) },
        }),
        _vm._v(" "),
        _vm.success
          ? _c("div", { staticClass: "alert alert-success" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("activate.messages.success")),
                },
              }),
              _vm._v(" "),
              _c("a", {
                staticClass: "alert-link",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.messages.info.authenticated.link")
                  ),
                },
                on: { click: _vm.openLogin },
              }),
              _vm._v(".\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", {
              staticClass: "alert alert-danger",
              domProps: {
                innerHTML: _vm._s(_vm.$t("activate.messages.error")),
              },
            })
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }