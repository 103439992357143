import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import TeamRequestsList from './team-requests-list/TeamRequestsList.vue';
import { ITeamRequest } from '@/shared/model/team-request.model';
import TeamRequestListSkeleton from '@/sections/shared/team-request-list-skeleton/TeamRequestListSkeleton.vue';

export default defineComponent({
  components: {
    TeamRequestsList,
    TeamRequestListSkeleton,
  },
  setup() {
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const teamRequestFiltered = ref<ITeamRequest[]>([]);
    const isLoading = ref(true);
    const displaySkeleton = computed(() => {
      return isLoading.value || (teamRequestFiltered.value.length === 0 && isLoading.value);
    });

    onMounted(() => {
      retrieveAllRequestsByManager();
    });

    const retrieveAllRequestsByManager = () => {
      isLoading.value = true;
      teamRequestService
        .getAllRequestsByManager()
        .then(res => {
          teamRequestFiltered.value = res.data;
          isLoading.value = false;
        })
        .catch(err => {
          console.error(err);
        });
    };

    return {
      teamRequestFiltered,
      displaySkeleton,
    };
  },
});
