export interface ITechnologyScore {
  idTechnology?: number;
  score?: number;
}

export class TechnologyScore implements ITechnologyScore {
  public idTechnology?: number;
  public score?: number;

  constructor(data: ITechnologyScore) {
    this.idTechnology = data.idTechnology;
    this.score = data.score;
  }
}

export class TechnologyScores extends Array<TechnologyScore> {
  constructor(data: TechnologyScore[]) {
    super(...data);
  }

  static empty = () => {
    return new TechnologyScores([]);
  };

  static from = (data: TechnologyScore[]): TechnologyScores => {
    return new TechnologyScores(data.map(technologyScore => new TechnologyScore(technologyScore)));
  };

  static fromI = (data: ITechnologyScore[]): TechnologyScores => {
    return new TechnologyScores(data.map(technologyScore => new TechnologyScore(technologyScore)));
  };

  orderByScoreDesc = () => {
    this.sort((a, b) => (b.score ?? 0) - (a.score ?? 0));
  };
}
