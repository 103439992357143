import { render, staticRenderFns } from "./TRValidatedModal.vue?vue&type=template&id=a64aa2f0"
import script from "./tRValidatedModal.ts?vue&type=script&lang=ts&external"
export * from "./tRValidatedModal.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a64aa2f0')) {
      api.createRecord('a64aa2f0', component.options)
    } else {
      api.reload('a64aa2f0', component.options)
    }
    module.hot.accept("./TRValidatedModal.vue?vue&type=template&id=a64aa2f0", function () {
      api.rerender('a64aa2f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/sections/shared/team-request-detail/buttons-group/modal/manager/trValidated/TRValidatedModal.vue"
export default component.exports