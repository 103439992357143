import { Module } from 'vuex';
import Filters, { FilterFields } from '@/sections/shared/explore/model/Filters';
import Filter from '@/sections/shared/explore/model/Filter';
import { IAtocompleteMultipleRatingState } from '@/components/stemdo-components/s-autocomplete-multiple-rating/sAutocompleteMultipleRating';
import { IAtocompleteMultipleState } from '@/components/stemdo-components/s-autocomplete-multiple/sAutocompleteMultiple';
import { IRangeSliderStepsRateState } from '@/components/stemdo-components/s-range-slider-steps-rate/sRangeSliderStepsRate';
import { IAtocompleteState } from '@/components/stemdo-components/s-autocomplete/sAutocomplete';
export interface FilterStateStorable {
  filters: Filters;
  tempFilters: Filters;
}

export const defaultFilterState: FilterStateStorable = {
  filters: Filters.defaulInitState(),
  tempFilters: Filters.defaulInitState(),
};

export const filterStore: Module<FilterStateStorable, any> = {
  namespaced: true,
  state: localStorage.getItem('filterStore')
    ? { filters: JSON.parse(localStorage.getItem('filterStore')), tempFilters: Filters.defaulInitState() }
    : { ...defaultFilterState },
  getters: {
    filters: state => getFiltersFromState(state),
    tempFilters: state => state.tempFilters,
    totalFilters: state => getFiltersFromState(state).getTotalFilters(),
    getAutoCompleteState: state => (field: FilterFields) => {
      const filters = getFiltersFromState(state);
      return filters.getAutoCompleteState(field);
    },
    getAutoCompleteTempState: state => (field: FilterFields) => {
      const filters = state.tempFilters;
      return filters.getAutoCompleteState(field);
    },
    getAutoCompleteMultipleState: state => (field: FilterFields) => {
      const filters = getFiltersFromState(state);
      return filters.getAutoCompleteMultipleState(field);
    },
    getAutoCompleteMultipleRatingState: state => (field: FilterFields) => {
      const filters = getFiltersFromState(state);
      return filters.getAutoCompleteMultipleRatingState(field);
    },
    getAutoCompleteMultipleTempState: state => (field: FilterFields) => {
      const filters = state.tempFilters;
      return filters.getAutoCompleteMultipleState(field);
    },
    getAutoCompleteMultipleRatingTempState: state => (field: FilterFields) => {
      const filters = state.tempFilters;
      return filters.getAutoCompleteMultipleRatingState(field);
    },
    getRangeSliderStepsRateState: state => (field: FilterFields) => {
      const filters = getFiltersFromState(state);
      return filters.getRangeSilderStesRateState(field);
    },
    getRangeSliderStepsRateTempState: state => (field: FilterFields) => {
      const filters = state.tempFilters;
      return filters.getRangeSilderStesRateState(field);
    },
    getCheckboxState: state => (field: FilterFields) => {
      const filters = getFiltersFromState(state);
      return filters.getCheckboxState(field);
    },
    getCheckboxTempState: state => (field: FilterFields) => {
      const filters = state.tempFilters;
      return filters.getCheckboxState(field);
    },
    getCheckboxListTempState: state => (field: FilterFields) => {
      const filters = state.tempFilters;
      return filters.getCheckboxListState(field);
    },
    getSwitchReverseState: state => (field: FilterFields) => {
      const filters = getFiltersFromState(state);
      return filters.getSwitchReverseState(field);
    },
    getSwitchReverseTempState: state => (field: FilterFields) => {
      const filters = state.tempFilters;
      return filters.getSwitchReverseState(field);
    },
  },
  mutations: {
    addFilter(state, filter: Filter) {
      const filters = getFiltersFromState(state);
      filters.add(filter);
      persistFiltersState(state, filters);
    },
    removeFilter(state, filter: Filter) {
      const filters = getFiltersFromState(state);
      filters.remove(filter);
      persistFiltersState(state, filters);
    },
    updateAutoCompleteState(state, payload: { field: FilterFields; newSelectedItem: IAtocompleteState }) {
      const filters = getFiltersFromState(state);
      filters.updateAutoCompleteState(payload.field, payload.newSelectedItem);
      persistFiltersState(state, filters);
    },
    updateAutoCompleteTempState(state, payload: { field: FilterFields; newSelectedItem: IAtocompleteState }) {
      const tempFilters = state.tempFilters;
      tempFilters.updateAutoCompleteState(payload.field, payload.newSelectedItem);
      state.tempFilters = tempFilters;
    },
    updateAutoCompleteMultipleState(state, payload: { field: FilterFields; newSelectedItems: IAtocompleteMultipleState[] }) {
      const filters = getFiltersFromState(state);
      filters.updateAutoCompleteMultipleState(payload.field, payload.newSelectedItems);
      persistFiltersState(state, filters);
    },
    updateAutoCompleteMultipleTempState(state, payload: { field: FilterFields; newSelectedItems: IAtocompleteMultipleState[] }) {
      const tempFilters = state.tempFilters;
      tempFilters.updateAutoCompleteMultipleState(payload.field, payload.newSelectedItems);
      state.tempFilters = tempFilters;
    },
    updateAutoCompleteMultipleRatingState(state, payload: { field: FilterFields; newSelectedItem: IAtocompleteMultipleRatingState }) {
      const filters = getFiltersFromState(state);
      filters.updateAutoCompleteMultipleRatingState(payload.field, payload.newSelectedItem);
      persistFiltersState(state, filters);
    },
    updateAutoCompleteMultipleRatingTempState(state, payload: { field: FilterFields; newSelectedItem: IAtocompleteMultipleRatingState }) {
      const tempFilters = state.tempFilters;
      tempFilters.updateAutoCompleteMultipleRatingState(payload.field, payload.newSelectedItem);
      state.tempFilters = tempFilters;
    },
    updateRangeSliderStepsRateState(state, payload: { field: FilterFields; newRateRange: IRangeSliderStepsRateState }) {
      const filters = getFiltersFromState(state);
      filters.updateRangeSliderStepsRateState(payload.field, payload.newRateRange);
      persistFiltersState(state, filters);
    },
    updateRangeSliderStepsRateTempState(state, payload: { field: FilterFields; newRateRange: IRangeSliderStepsRateState }) {
      const tempFilters = state.tempFilters;
      tempFilters.updateRangeSliderStepsRateState(payload.field, payload.newRateRange);
      state.tempFilters = tempFilters;
    },
    updateCheckboxState(state, payload: { field: FilterFields; newVal: boolean }) {
      const filters = getFiltersFromState(state);
      filters.updateCheckboxState(payload.field, payload.newVal);
      persistFiltersState(state, filters);
    },
    updateCheckboxTempState(state, payload: { field: FilterFields; newVal: boolean }) {
      const tempFilters = state.tempFilters;
      tempFilters.updateCheckboxState(payload.field, payload.newVal);
      state.tempFilters = tempFilters;
    },
    updateCheckboxListTempState(state, payload: { field: FilterFields; newVal: number }) {
      const tempFilters = state.tempFilters;
      tempFilters.updateCheckboxListState(payload.field, payload.newVal);
      state.tempFilters = tempFilters;
    },
    updateSwitchReverseState(state, payload: { field: FilterFields; newVal: boolean }) {
      const filters = getFiltersFromState(state);
      filters.updateSwitchReverseState(payload.field, payload.newVal);
      persistFiltersState(state, filters);
    },
    clearAllFilters(state) {
      persistFiltersState(state, Filters.defaulClearState());
    },
    clearToInitState(state) {
      persistFiltersState(state, Filters.defaulInitState());
    },
    resetTempFilters(state) {
      const filters = getFiltersFromState(state);
      const tempFilters: Filters = Filters.clone(filters);
      state.tempFilters = tempFilters;
    },
    commitTempFilters(state) {
      const tempfilters = state.tempFilters;
      const filters: Filters = Filters.clone(tempfilters);
      persistFiltersState(state, filters);
    },
  },
};

const getFiltersFromState = (state): Filters => {
  return Filters.clone(state.filters);
};

const persistFiltersState = (state, filters: Filters) => {
  state.filters = filters;
  localStorage.setItem('filterStore', JSON.stringify(filters));
};
