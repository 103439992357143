import { defineComponent, inject, onMounted, ref, computed, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import StemdoerService from '@/sections/admin/entities/stemdoer/stemdoer.service';
import ClientRateService from '@/sections/admin/entities/client-rate/client-rate.service';
import { IStemdoer, Stemdoers } from '@/shared/model/stemdoer.model';
import { IRate, Rate, Rates } from '@/shared/model/rate.model';
import StemdoerList from './stemdoer-list/StemdoerList.vue';
import TopFilter from './top-filter/TopFilter.vue';
import BtnMyTeam from './btn-my-team/BtnMyTeam.vue';
import NoResultInfo from './no-result-info/NoResultInfo.vue';
import Filters, { FilterFields } from './model/Filters';
import SwitchAvailability from './switch-availability/SwitchAvailability.vue';
import { filterStemdoers, filterStemdoersClosestMatch } from './model/StemdoerFilter';
import StemdoerListSkeleton from './stemdoer-list-skeleton/StemdoerListSkeleton.vue';
import ClientSelector from './client-selector/ClientSelector.vue';
import { IAtocompleteProps } from './model/Filter';
import EmptyStemdoerList from './empty-stemdoer-list/EmptyStemdoerList.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';

export default defineComponent({
  props: {
    teamRequestId: {
      type: String,
      required: false
    },
    loadTrToCart: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    TopFilter,
    StemdoerList,
    SwitchAvailability,
    BtnMyTeam,
    NoResultInfo,
    StemdoerListSkeleton,
    ClientSelector,
    EmptyStemdoerList
  },
  setup(props) {
    const store = useStore();
    const stemdoerService: StemdoerService = inject('stemdoerService');
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const clientRateService: ClientRateService = inject('clientRateService');

    const stemdoers = ref<Stemdoers>(Stemdoers.empty());
    const stemdoersNotInCart = ref<Stemdoers>(Stemdoers.empty());
    const visibleStemdoers = ref<Stemdoers>(Stemdoers.empty());
    const rates = ref<Rates>(Rates.empty());
    const isLoading = ref(true);
    const isFiltered = ref(false);
    const isFilteredByClosestMatch = ref(false);
    const isManager = computed(() => store.getters.account?.authorities.includes('ROLE_GESTOR') ?? false);
    const defaultClientSelector = computed(() => {
      const client = store.getters['cartStore/clientSelected'];
      return props.teamRequestId ? client : null;
    });
    const cartTotalItems = computed(() => {
      return store.getters['cartStore/cartTotalItems'];
    });
    const displaySkeleton = computed(() => {
      return isLoading.value || (stemdoers.value.length === 0 && isLoading.value);
    });
    const isStemdoersListEmpty = computed(() => {
      if (visibleStemdoers.value.length == 0) {
        return true;
      }
      return false;
    });

    onMounted(async () => {
      if (!isManager.value) {
        rates.value = await clientRateService.retrieveClientRates();
      }
      retrieveAllStemdoers();
      if (props.loadTrToCart === true && props.teamRequestId) loadTeamRequestInfo(props.teamRequestId);
    });

    watch(
      () => store.getters['filterStore/filters'],
      (filters: Filters, oldFilters: Filters) => {
        if (hasTechFilterChanged(filters, oldFilters)) {
          retrieveAllStemdoers();
        } else {
          visibleStemdoers.value = filter(filters, stemdoersNotInCart.value);
        }
      },
      { deep: true }
    );

    const hasTechFilterChanged = (newFiltersState: Filters, oldFiltersState: Filters) => {
      const oldTechFilter = oldFiltersState.getFiltersByField(FilterFields.Technology)[0];
      const newTechFilter = newFiltersState.getFiltersByField(FilterFields.Technology)[0];
      const oldTechFilterProps = oldTechFilter?.props as IAtocompleteProps;
      const newTechFilterProps = newTechFilter?.props as IAtocompleteProps;
      return !oldTechFilter || !newTechFilter || oldTechFilterProps.value.id !== newTechFilterProps.value.id;
    };

    const loadTeamRequestInfo = (teamRequestId: string) => {
      store.commit('cartStore/clearCart');
      teamRequestService.find(teamRequestId).then(tr => {
        store.commit('cartStore/setClient', tr.client);
        tr.stemdoerRates.forEach(sr => {
          if (sr.stemdoer.availability.available == true) {
            store.commit('cartStore/addStemdoer', sr.stemdoer);
          }
        });
      });
    };

    const retrieveAllStemdoers = () => {
      isFiltered.value = false;
      isLoading.value = true;

      const filters = store.getters['filterStore/filters'];
      const techFilter = filters.find(filter => filter.field == FilterFields.Technology);

      if (techFilter && techFilter.props && techFilter.props.value) {
        const verticalId = techFilter.props.value.id;

        stemdoerService
          .retrieveStemdoersByDate(verticalId, new Date())
          .then(res => {
            stemdoers.value = Stemdoers.from(res.data);
            stemdoers.value.setRates(rates.value);
            stemdoersNotInCart.value = getStemdoersNotInCart(stemdoers.value);
            visibleStemdoers.value = filter(filters, stemdoersNotInCart.value);
            isLoading.value = false;
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        isLoading.value = false;
      }
    };

    const getStemdoersNotInCart = (stemdoers: Stemdoers): Stemdoers => {
      let stemdoersNotInCart = Stemdoers.from([...stemdoers]);
      if (cartTotalItems.value > 0) {
        const cartItemsIds = store.getters['cartStore/cartItems'].map(s => s.id);
        stemdoersNotInCart = stemdoersNotInCart.getFilterByIds(cartItemsIds);
      }
      return stemdoersNotInCart;
    };

    const filter = (filters: Filters, stemdoers: Stemdoers): Stemdoers => {
      let filteredStemdoers = [...stemdoers];
      filteredStemdoers = Stemdoers.fromI(filterStemdoers(filters, filteredStemdoers));
      isFiltered.value = true;
      isFilteredByClosestMatch.value = false;
      if (filteredStemdoers.length === 0) {
        filteredStemdoers = Stemdoers.fromI(filterByClosestMatch(filters, stemdoersNotInCart.value));
      }
      return Stemdoers.from(filteredStemdoers);
    };

    const filterByClosestMatch = (filters: Filters, stemdoers: IStemdoer[]): IStemdoer[] => {
      let filteredStemdoers: IStemdoer[] = [...stemdoers];
      filteredStemdoers = filterStemdoersClosestMatch(filters, filteredStemdoers);
      isFiltered.value = true;
      isFilteredByClosestMatch.value = true;
      return filteredStemdoers;
    };

    const addStemdoerToCart = (stemdoerId: string) => {
      const stemdoer = stemdoers.value.getById(stemdoerId);
      stemdoersNotInCart.value = visibleStemdoers.value.getFilterById(stemdoerId);
      visibleStemdoers.value = visibleStemdoers.value.getFilterById(stemdoerId);
      store.commit('cartStore/addStemdoer', stemdoer);
      document.documentElement.style.overflow = 'auto';
    };

    const changeAvailableFilter = (field: FilterFields, newVal: boolean) => {
      const payload = { field, newVal };
      store.commit('filterStore/updateSwitchReverseState', payload);
    };

    return {
      stemdoers,
      rates,
      visibleStemdoers,
      FilterFields,
      isFiltered,
      defaultClientSelector,
      isFilteredByClosestMatch,
      displaySkeleton,
      isLoading,
      isManager,
      isStemdoersListEmpty,
      addStemdoerToCart,
      changeAvailableFilter
    };
  }
});
