var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mt-12" }, [
    _c("div", { attrs: { id: "noContent" } }, [
      _c(
        "div",
        { staticClass: "frame-nocontent mb-5" },
        [
          _c("v-img", {
            staticClass: "img-nocontent",
            attrs: { width: 30, src: "/content/svgs/9-box.svg" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", {
        staticClass: "mb-1 text-body-1-bold text-no-content-title",
        domProps: {
          textContent: _vm._s(_vm.$t("no-content-explorer.title-text")),
        },
      }),
      _vm._v(" "),
      _c("p", {
        staticClass: "text-no-content text-subtitle-3",
        domProps: {
          textContent: _vm._s(_vm.$t("no-content-explorer.description-01")),
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }