import { computed, inject, onMounted, ref, watch } from 'vue';
import { useI18N } from '@/plugins/i18n';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    stemdoers: {
      type: Array as () => IStemdoer[],
      required: true,
    },
  },

  components: {
    SAvatarStemdoer,
  },

  setup(props) {
    const stemdoers = computed(() => {
      return props.stemdoers.filter(stemdoer => stemdoer.availability && stemdoer.availability.available === false);
    });

    const handleClose = () => {
      props.onClose();
    };

    return {
      stemdoers,
      handleClose,
    };
  },
};
