var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "login" } }, [
    _c("div", { staticClass: "login-container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "login-form", attrs: { "no-validate": "" } }, [
        _c("div", { staticClass: "form-text" }, [
          _c("div", [
            _c("span", {
              staticClass: "text-body-1",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.messages.info.register.account")
                ),
              },
            }),
            _vm._v(" "),
            _c("a", {
              staticClass: "alert-link text-body-1-bold",
              staticStyle: { color: "#4237ff" },
              attrs: { "data-cy": "registerOpen" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.messages.info.register.toLogin")
                ),
              },
              on: { click: _vm.gotoLogin },
            }),
          ]),
        ]),
        _vm._v(" "),
        !_vm.success
          ? _c(
              "form",
              {
                staticClass: "SForm",
                attrs: { name: "form", role: "form" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "form-title" }, [
                  _c("h1", {
                    domProps: { textContent: _vm._s(_vm.$t("reset.title")) },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    domProps: { textContent: _vm._s(_vm.$t("reset.info")) },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "login-span", attrs: { for: "email" } },
                  [_vm._v(_vm._s(_vm.$t("login.form['username']")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "input-form-group-stemdo",
                    attrs: { id: "inpunts" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.resetAccount.email.$model,
                          expression: "$v.resetAccount.email.$model",
                        },
                      ],
                      staticClass: "input-form-stemdo",
                      class: {
                        valid: !_vm.$v.resetAccount.email.$invalid,
                        invalid: _vm.$v.resetAccount.email.$invalid,
                        "input-error":
                          (_vm.$v.resetAccount.email.$invalid &&
                            _vm.$v.resetAccount.email.$dirty) ||
                          _vm.isFormInvalid,
                      },
                      attrs: {
                        id: "email",
                        type: "email",
                        name: "email",
                        email: "",
                        required: "",
                        "data-cy": "emailResetPassword",
                        state: !_vm.$v.resetAccount.email.$invalid,
                      },
                      domProps: { value: _vm.$v.resetAccount.email.$model },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.$v.resetAccount.email,
                            "$model",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                (_vm.$v.resetAccount.email.$invalid &&
                  _vm.$v.resetAccount.email.$dirty) ||
                (_vm.isFormInvalid &&
                  !_vm.$v.resetAccount.email.$model &&
                  _vm.$v.resetAccount.email.$invalid)
                  ? _c("span", {
                      staticClass: "span-error",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.messages.validate.email.required")
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "22px" } },
                  [
                    _c("SButton", {
                      attrs: {
                        btnStyle: "primary",
                        sText: "reset.request.form.instructions",
                        action: _vm.validateForm,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.success
          ? _c("div", { staticStyle: { width: "60%" } }, [
              _c("h5", {
                domProps: {
                  textContent: _vm._s(_vm.$t("reset.request.messages.success")),
                },
              }),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "login-image", attrs: { "no-validate": "" } },
      [
        _c("img", {
          attrs: {
            src: require("@content/images/stemdo-black-logo.png"),
            alt: "stemdo Logo",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }