import { defineComponent, inject, PropType, ref, watch } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { TeamRequest } from '@/shared/model/team-request.model';
import SModalTeamRequestConfirmation from '@/components/stemdo-components/s-modal-team-request-confirmation/SModalTeamRequestConfirmation.vue';

import { ButtonGroupModals } from '../../../buttonsGroup';
import { useRouter } from '@/plugins/router';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    onOpenModal: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton,
    SModalCentered,
    SModalTeamRequestConfirmation
  },
  setup(props) {
    const router = useRouter();
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const isTeamRequestConfirmationModalOpen = ref(false);

    const openTeamRequestConfirmationModal = () => {
      isTeamRequestConfirmationModalOpen.value = true;
    };

    const closeTeamRequestConfirmationModal = () => {
      isTeamRequestConfirmationModalOpen.value = false;
    };

    const saveTeamRequest = (teamRequest: TeamRequest) => {
      teamRequestService
        .partialUpdate(teamRequest)
        .then(_ => {
          router.push({ name: 'teamRequestsClient', query: { 'saved-success': 'true' } });
        })
        .catch(error => {
          console.error(error);
        });
    };

    const requestTeamRequest = (teamRequest: TeamRequest) => {
      teamRequestService
        .blockAndPartialUpdate(teamRequest)
        .then(_ => {
          props.onOpenModal(ButtonGroupModals.CONFIRMATION);
        })
        .catch(error => {
          console.error(error);
        });
    };

    const onClickTeamRequestConfirmationModal = (teamRequestName: string, initDate: string, endDate: string, jobDescription: string) => {
      const mutableTeamRequest = { ...props.teamRequest };
      mutableTeamRequest.name = teamRequestName;
      mutableTeamRequest.initDate = new Date(initDate);
      mutableTeamRequest.endDate = endDate ? new Date(endDate) : null;
      mutableTeamRequest.jobDescription = jobDescription;
      requestTeamRequest(mutableTeamRequest);
    };

    return {
      isTeamRequestConfirmationModalOpen,
      saveTeamRequest,
      openTeamRequestConfirmationModal,
      closeTeamRequestConfirmationModal,
      onClickTeamRequestConfirmationModal
    };
  }
});
