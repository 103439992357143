import { defineComponent, inject, onMounted, ref, Ref, watch } from 'vue';
import ClientService from '@/sections/admin/entities/client/client.service';
import { Client } from '@/shared/model/client.model';
import { useStore } from '@/plugins/vuex';

export default defineComponent({
  props: {
    defaultClientId: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const clientService = inject('clientService') as () => ClientService;
    const clients: Ref<Client[]> = ref([]);
    const selectedItem = ref<Client>(store.getters['cartStore/clientSelected']);
    const isDisabled = ref(false);

    onMounted(async () => {
      await retrieveAllClients();
      if (props.defaultClientId) {
        setDefaultClient(clients.value.find(client => client.id === props.defaultClientId));
        return;
      }
    });

    watch(
      () => props.defaultClientId,
      newValue => {
        setDefaultClient(clients.value.find(client => client.id === props.defaultClientId));
      }
    );

    const setDefaultClient = (client: Client) => {
      isDisabled.value = true;
      selectedItem.value = client;
    };

    const retrieveAllClients = async () => {
      try {
        const response = await clientService().retrieveWithRates();
        clients.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const onClientSelected = (client: Client) => {
      store.commit('cartStore/setClient', client);
    };

    return {
      selectedItem,
      clients,
      isDisabled,
      onClientSelected
    };
  }
});
