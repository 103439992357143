var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "p-0" },
    [
      _c(
        "v-row",
        { attrs: { justify: "space-between", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("ExperienceLevelCard", {
                attrs: {
                  filterField: _vm.filterField,
                  value: _vm.experienceLevelOptions.explore,
                  action: _vm.handleChange,
                  isActive: _vm.selected === _vm.experienceLevelOptions.explore,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("ExperienceLevelCard", {
                attrs: {
                  filterField: _vm.filterField,
                  value: _vm.experienceLevelOptions.strategist,
                  action: _vm.handleChange,
                  isActive:
                    _vm.selected === _vm.experienceLevelOptions.strategist,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("ExperienceLevelCard", {
                attrs: {
                  filterField: _vm.filterField,
                  value: _vm.experienceLevelOptions.visionary,
                  action: _vm.handleChange,
                  isActive:
                    _vm.selected === _vm.experienceLevelOptions.visionary,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }