import axios from 'axios';
import { Store } from 'vuex';
import TranslationService from '@/locale/translation.service';
import ClientService from '@/sections/admin/entities/client/client.service';

export default class AccountService {
  constructor(private store: Store<any>, private translationService: TranslationService, private clientService: ClientService) {
    this.init();
  }

  public init(): void {
    this.retrieveProfiles();
  }

  public retrieveProfiles(): Promise<boolean> {
    return new Promise(resolve => {
      axios
        .get<any>('management/info')
        .then(res => {
          if (res.data && res.data.activeProfiles) {
            this.store.commit('setRibbonOnProfiles', res.data['display-ribbon-on-profiles']);
            this.store.commit('setActiveProfiles', res.data['activeProfiles']);
          }
          resolve(true);
        })
        .catch(() => resolve(false));
    });
  }

  public retrieveAccount(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get<any>('api/account')
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public async retrieveClientDetails(email: string) {
    await this.clientService.findByEmail(email).then((client: any) => {
      this.store.commit('addClient', client);
    });
  }

  public get authenticated(): boolean {
    return this.store.getters.authenticated;
  }

  public get userAuthorities(): any {
    return this.store.getters.account?.authorities;
  }
}
