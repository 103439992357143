var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 toastify-container" }, [
        _vm.account
          ? _c("h2", { attrs: { id: "password-title" } }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("password.title", { username: _vm.username })
                  ),
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.success
          ? _c("div", {
              staticClass: "alert alert-success",
              attrs: { role: "alert" },
              domProps: {
                innerHTML: _vm._s(_vm.$t("password.messages.success")),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", {
              staticClass: "alert alert-danger",
              attrs: { role: "alert" },
              domProps: {
                innerHTML: _vm._s(_vm.$t("password.messages.error")),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.doNotMatch
          ? _c("div", {
              staticClass: "alert alert-danger",
              attrs: { role: "alert" },
              domProps: {
                textContent: _vm._s(_vm.$t("global.messages.error.dontmatch")),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            attrs: { name: "form", role: "form", id: "password-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.changePassword()
              },
            },
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("label", {
                staticClass: "form-control-label",
                attrs: { for: "currentPassword" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.form['currentpassword.label']")
                  ),
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.resetPassword.currentPassword.$model,
                    expression: "$v.resetPassword.currentPassword.$model",
                  },
                ],
                staticClass: "form-control",
                class: {
                  valid: !_vm.$v.resetPassword.currentPassword.$invalid,
                  invalid: _vm.$v.resetPassword.currentPassword.$invalid,
                },
                attrs: {
                  type: "password",
                  id: "currentPassword",
                  name: "currentPassword",
                  placeholder: _vm.$t(
                    "global.form['currentpassword.placeholder']"
                  ),
                  required: "",
                  "data-cy": "currentPassword",
                },
                domProps: {
                  value: _vm.$v.resetPassword.currentPassword.$model,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$v.resetPassword.currentPassword,
                      "$model",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.$v.resetPassword.currentPassword.$anyDirty &&
              _vm.$v.resetPassword.currentPassword.$invalid
                ? _c("div", [
                    !_vm.$v.resetPassword.currentPassword.required
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "global.messages.validate.newpassword.required"
                              )
                            ),
                          },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", {
                staticClass: "form-control-label",
                attrs: { for: "newPassword" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.form['newpassword.label']")
                  ),
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.resetPassword.newPassword.$model,
                    expression: "$v.resetPassword.newPassword.$model",
                  },
                ],
                staticClass: "form-control",
                class: {
                  valid: !_vm.$v.resetPassword.newPassword.$invalid,
                  invalid: _vm.$v.resetPassword.newPassword.$invalid,
                },
                attrs: {
                  type: "password",
                  id: "newPassword",
                  name: "newPassword",
                  placeholder: _vm.$t("global.form['newpassword.placeholder']"),
                  minlength: "4",
                  maxlength: "50",
                  required: "",
                  "data-cy": "newPassword",
                },
                domProps: { value: _vm.$v.resetPassword.newPassword.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$v.resetPassword.newPassword,
                      "$model",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.$v.resetPassword.newPassword.$anyDirty &&
              _vm.$v.resetPassword.newPassword.$invalid
                ? _c("div", [
                    !_vm.$v.resetPassword.newPassword.required
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "global.messages.validate.newpassword.required"
                              )
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.resetPassword.newPassword.minLength
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "global.messages.validate.newpassword.minlength"
                              )
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.resetPassword.newPassword.maxLength
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "global.messages.validate.newpassword.maxlength"
                              )
                            ),
                          },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", {
                staticClass: "form-control-label",
                attrs: { for: "confirmPassword" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.form['confirmpassword.label']")
                  ),
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.resetPassword.confirmPassword.$model,
                    expression: "$v.resetPassword.confirmPassword.$model",
                  },
                ],
                staticClass: "form-control",
                class: {
                  valid: !_vm.$v.resetPassword.confirmPassword.$invalid,
                  invalid: _vm.$v.resetPassword.confirmPassword.$invalid,
                },
                attrs: {
                  type: "password",
                  id: "confirmPassword",
                  name: "confirmPassword",
                  placeholder: _vm.$t(
                    "global.form['confirmpassword.placeholder']"
                  ),
                  minlength: "4",
                  maxlength: "50",
                  required: "",
                  "data-cy": "confirmPassword",
                },
                domProps: {
                  value: _vm.$v.resetPassword.confirmPassword.$model,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$v.resetPassword.confirmPassword,
                      "$model",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.$v.resetPassword.confirmPassword.$anyDirty &&
              _vm.$v.resetPassword.confirmPassword.$invalid
                ? _c("div", [
                    !_vm.$v.resetAccount.confirmPassword.sameAsPassword
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.messages.error.dontmatch")
                            ),
                          },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn btn-primary",
              attrs: {
                type: "submit",
                disabled: _vm.$v.resetPassword.$invalid,
                "data-cy": "submit",
              },
              domProps: { textContent: _vm._s(_vm.$t("password.form.button")) },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }