var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "p-0" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("SButton", {
                attrs: {
                  sText: _vm.$t(
                    "checkout.buttonGroup.client.validated.primaryBtn"
                  ),
                  btnStyle: "primary",
                  action: () => _vm.openTeamRequestConfirmationModal(),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SModalTeamRequestConfirmation", {
        attrs: {
          teamRequest: _vm.teamRequest,
          isOpen: _vm.isTeamRequestConfirmationModalOpen,
          onClose: _vm.closeTeamRequestConfirmationModal,
          action: _vm.onClickTeamRequestConfirmationModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }