var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "stretched home-container" }, [
    _c("div", { attrs: { id: "wrapper" } }, [
      _c(
        "div",
        {
          class:
            "container " +
            (_vm.$vuetify.breakpoint.xsOnly ? "pt-4 px-4 mb-2 " : "pt-3 mb-6"),
        },
        [
          _c(
            "div",
            { staticClass: "d-inline-flex w-100 justify-content-start" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-inline-flex w-50 justify-content-end" },
                [
                  _c("div", { staticClass: "center-img text-body-color" }, [
                    _c("a", [
                      _c("div", { on: { click: _vm.goToLoginPage } }, [
                        _vm._v("Conéctate"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$vuetify.breakpoint.smAndUp,
                          expression: "$vuetify.breakpoint.smAndUp",
                        },
                      ],
                      staticClass: "ms-5",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "stemdo-button-small",
                          on: { click: _vm.goToRegisterPage },
                        },
                        [_vm._v("Pruébanos")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          class: _vm.$vuetify.breakpoint.xsOnly ? "px-3" : "",
          attrs: { id: "content" },
        },
        [
          _c("div", { staticClass: "content-wrap" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row col-mb-0 mb-0" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "heading-block text-center border-bottom-0 text-main-color",
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "ppmountent",
                          style: this.$vuetify.breakpoint.xsOnly
                            ? "fontSize: var(--cnvs-heading-block-font-size-h1)"
                            : "",
                        },
                        [
                          _vm._v(
                            "\n                  STEMDO PLATFORM\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("La primera plataforma de talento digital B2B"),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "stemdo-button-regular",
                            on: { click: _vm.goToRegisterPage },
                          },
                          [_vm._v("Pruébanos")]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _vm._m(6),
                _vm._v(" "),
                _vm._m(7),
                _vm._v(" "),
                _vm._m(8),
                _vm._v(" "),
                _vm._m(9),
                _vm._v(" "),
                _vm._m(10),
                _vm._v(" "),
                _vm._m(11),
                _vm._v(" "),
                _vm._m(12),
              ]),
              _vm._v(" "),
              _vm._m(13),
              _vm._v(" "),
              _vm._m(14),
              _vm._v(" "),
              _vm._m(15),
              _vm._v(" "),
              _vm._m(16),
              _vm._v(" "),
              _vm._m(17),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "heading-block join-us-wrapper mt-5 mt-lg-7 px-4 px-lg-7 pb-4 pb-lg-6 pt-5 pt-lg-6",
                },
                [
                  _vm._m(18),
                  _vm._v(" "),
                  _vm._m(19),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "margin-top": "32px" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "stemdo-button-white",
                        on: { click: _vm.goToRegisterPage },
                      },
                      [_c("b", [_vm._v("Pruébanos")])]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "join-us-stemdoer-wrapper mb-3 mb-lg-5 mt-5 px-4 px-lg-5 py-4",
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _vm._m(20),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 col-lg-3 text-center text-lg-end",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "join-us-button",
                            on: { click: _vm.goToCandidatesTypeForm },
                          },
                          [_vm._v("Únete")]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._m(21),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "w-50", attrs: { id: "logo" } }, [
      _c("div", [
        _c("img", {
          staticClass: "logo-default",
          attrs: {
            srcset: "/content/images/stemdo-black-logo.png",
            src: "/content/images/stemdo-black-logo.png",
            alt: "Stemdo Logo",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-12 mt-0 mt-lg-6 mb-0" }, [
      _c(
        "div",
        {
          staticClass:
            "heading-block text-center border-bottom-0 text-dark-grey-color mb-4",
        },
        [
          _c(
            "span",
            {
              staticClass: "mx-2 text-center w-100",
              staticStyle: {
                "min-width": "100%",
                "font-size": "16px",
                "font-weight": "700",
                "font-style": "normal",
                color: "var(--Colors-Dark-grey, #606061)",
                "margin-bottom": "0 !important",
              },
            },
            [
              _vm._v(
                "\n                  Estas empresas ya han incorporado talento tech a sus equipos de forma inmediata con STEMDO Platform\n                "
              ),
            ]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-2 center-img mb-5 mb-lg-0" }, [
      _c("img", {
        attrs: {
          srcset: "/content/images/aena-logo.png",
          src: "/content/images/aena-logo.png",
          alt: "Aena Logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-2 center-img mb-5 mb-lg-0" }, [
      _c("img", {
        attrs: {
          srcset: "/content/images/bbva-logo.png",
          src: "/content/images/bbva-logo.png",
          alt: "BBVA Logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-2 center-img mb-5 mb-lg-0" }, [
      _c("img", {
        attrs: {
          srcset: "/content/images/deloitte-logo.png",
          src: "/content/images/deloitte-logo.png",
          alt: "Deloitte Logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-2 center-img mb-5 mb-lg-0" }, [
      _c("img", {
        attrs: {
          srcset: "/content/images/pagonxt-logo.png",
          src: "/content/images/pagonxt-logo.png",
          alt: "Pagonxt Logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-2 center-img mb-5 mb-lg-0" }, [
      _c("img", {
        attrs: {
          srcset: "/content/images/rsi-logo.png",
          src: "/content/images/rsi-logo.png",
          alt: "RSI Logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-2 center-img mb-5 mb-lg-0" }, [
      _c("img", {
        attrs: {
          srcset: "/content/images/santander-logo.png",
          src: "/content/images/santander-logo.png",
          alt: "Santander Logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-12 mt-4 mt-lg-7" }, [
      _c(
        "div",
        {
          staticClass:
            "heading-block text-center border-bottom-0 text-body-color",
          staticStyle: { "margin-bottom": "32px !important" },
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "32px",
                "margin-bottom": "0 !important",
              },
            },
            [_vm._v(" Una plataforma que ofrece resultados ")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-3 text-center" }, [
      _c("i", { staticClass: "i-xlarge mx-auto mb-0 bi-person" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "counter counter-large",
          staticStyle: { color: "#4237ff" },
        },
        [_vm._v("+100")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-dark-grey-color" }, [
        _vm._v("Proyectos completados"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-3 text-center" }, [
      _c("i", { staticClass: "i-xlarge mx-auto mb-0 bi-code" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "counter counter-large",
          staticStyle: { color: "#8859ff" },
        },
        [_vm._v("+30")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-dark-grey-color" }, [
        _vm._v("Tecnologías diferentes"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-3 text-center" }, [
      _c("i", { staticClass: "i-xlarge mx-auto mb-0 bi-briefcase" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "counter counter-large",
          staticStyle: { color: "#00c2b1" },
        },
        [_vm._v("+150")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-dark-grey-color" }, [
        _vm._v("Stemdoers en proyectos"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-lg-3 text-center" }, [
      _c("i", { staticClass: "i-xlarge mx-auto mb-0 bi-cup" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "counter counter-large",
          staticStyle: { color: "#8859ff" },
        },
        [_vm._v("+50")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-dark-grey-color" }, [
        _vm._v("Clientes satisfechos"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row col-mb-50 mb-0 mt-6 mt-lg-7" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "team team-list row align-items-center" }, [
          _c("div", { staticClass: "team-desc col-sm-6" }, [
            _c("div", [
              _c(
                "h4",
                {
                  staticClass: "team-title ppmountent text-body-color",
                  staticStyle: { "margin-bottom": "18px !important" },
                },
                [_vm._v("¿QUÉ ES STEMDO PLATFORM?")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "team-content text-dark-grey-color" }, [
              _c("p", { staticStyle: { "margin-bottom": "12px" } }, [
                _vm._v(
                  "\n                      Somos una plataforma que suple la demanda de talento cualificado en el mercado para empresas que necesitan\n                      incorporar refuerzos de plantilla.\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Stemdo platform nace con la idea de simplificar los procesos de selección y agilizarlos de manera transparente."
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "team-desc p-0 p-sm-3 col-sm-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "team-image col-sm-5" }, [
            _c("img", {
              attrs: { src: "/content/images/work1.png", alt: "Working" },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "team team-list row align-items-center" }, [
          _c(
            "div",
            { staticClass: "team-image col-sm-5 order-last order-sm-1" },
            [
              _c("img", {
                attrs: { src: "/content/images/work3.png", alt: "Working" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "team-desc p-0 p-sm-3 col-sm-1 order-2 order-sm-2",
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "team-desc col-sm-6 order-1 order-sm-last" },
            [
              _c("div", { staticClass: "team-title" }, [
                _c(
                  "h4",
                  {
                    staticClass: "team-title ppmountent text-body-color",
                    staticStyle: { "margin-bottom": "18px !important" },
                  },
                  [_vm._v("NUESTRO ECOSYSTEM")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "team-content text-dark-grey-color" }, [
                _c("p", { staticStyle: { "margin-bottom": "12px" } }, [
                  _vm._v(
                    "Nosotros ponemos el talento, seleccionado, formado y avalado por nosotros."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                      Conectamos empresas con talentos tecnológicos altamente capacitados en las últimas tecnologías, para que puedan\n                      colaborar y trabajar juntos en proyectos innovadores.\n                    "
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "team team-list row align-items-center" }, [
          _c("div", { staticClass: "team-desc col-sm-6" }, [
            _c("div", { staticClass: "team-title" }, [
              _c(
                "h4",
                {
                  staticClass: "team-title ppmountent text-body-color",
                  staticStyle: { "margin-bottom": "18px !important" },
                },
                [_vm._v("TALENTO STEMDO")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "team-content text-dark-grey-color" }, [
              _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                _vm._v(
                  "\n                      Soluciones de talento a medida. Adaptamos nuestros servicios a tus necesidades, los stemdoers están especializados y\n                      certificados en las tecnologías más innovadoras y distribuidos en cuatro verticales:\n                      "
                ),
                _c("b", [
                  _vm._v(
                    "Cloud & DevSecOps, Hyperautomation, Data & MLOps y Ciberseguridad"
                  ),
                ]),
                _vm._v(".\n                    "),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "social-icon bg-light rounded-circle border-transparent si-large",
                attrs: { href: "#" },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/content/images/appian-certified-associate-logo.png",
                    alt: "Appian certified",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "social-icon bg-light rounded-circle border-transparent si-large",
                attrs: { href: "#" },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/content/images/azure-microsoft-certified-fundamentals-logo.png",
                    alt: "Microsoft certified",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "social-icon bg-light rounded-circle border-transparent si-large",
                attrs: { href: "#" },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/content/images/aws-certified-cloud-practitioner-logo.png",
                    alt: "AWS certified",
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "team-desc p-0 p-sm-3 col-sm-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "team-image col-sm-5" }, [
            _c("img", {
              attrs: { src: "/content/images/work2.png", alt: "Working" },
            }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "heading-block text-center border-bottom-0 text-main-color mt-5 mt-lg-7",
      },
      [_c("h2", [_vm._v("¿Cómo funciona?")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-12 mt-4 mt-lg-6" }, [
      _c("div", { staticClass: "heading-block text-center border-bottom-0" }, [
        _c("div", { staticStyle: { "margin-bottom": "26px" } }, [
          _c("img", {
            staticStyle: { "max-height": "55px" },
            attrs: { src: "/content/images/ellipse-1.png", alt: "1" },
          }),
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "text-main-color ppmountent" }, [
          _vm._v("FORMA TU EQUIPO"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "text-dark-grey-color",
            staticStyle: { "margin-top": "16px" },
          },
          [
            _vm._v(
              "\n                Entra a la plataforma y selecciona entre nuestros stemdoers los que se ajustan a tus necesidades. Descríbenos tu proyecto,\n                selecciona el periodo de tiempo y nosotros nos encargamos de todo lo demás.\n              "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-12 mt-4 mt-lg-6" }, [
      _c("div", { staticClass: "heading-block text-center border-bottom-0" }, [
        _c("div", { staticStyle: { "margin-bottom": "26px" } }, [
          _c("img", {
            staticStyle: { "max-height": "55px" },
            attrs: { src: "/content/images/ellipse-2.png", alt: "2" },
          }),
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "text-main-color ppmountent" }, [
          _vm._v("VALIDA TU PROPUESTA"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "text-dark-grey-color",
            staticStyle: { "margin-top": "16px" },
          },
          [
            _vm._v(
              "\n                Nuestros team leaders revisarán tu petición y te asesorarán en base a sus conocimientos sobre el negocio y nuestros\n                stemdoers si tu equipo necesita alguna modificación para que aún sea más eficiente.\n              "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-12 mt-4 mt-lg-6" }, [
      _c("div", { staticClass: "heading-block text-center border-bottom-0" }, [
        _c("div", { staticStyle: { "margin-bottom": "26px" } }, [
          _c("img", {
            staticStyle: { "max-height": "55px" },
            attrs: { src: "/content/images/ellipse-3.png", alt: "3" },
          }),
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "text-main-color ppmountent" }, [
          _vm._v("SOPORTE CONTINUO"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "text-dark-grey-color",
            staticStyle: { "margin-top": "16px" },
          },
          [
            _vm._v(
              "\n                Siempre tendrás el control de tu equipo. Si durante la duración del proyecto necesitas hacer modificaciones podrás hacerlo\n                sin problema.\n              "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticStyle: { "margin-bottom": "24px" } }, [
      _c("h3", [_vm._v("Únete a la nueva forma de encontrar talento digital")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("span", [
        _vm._v("Nuestros stemdoers te acompañarán en todos tus proyectos"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "col-12 col-lg-9 text-center text-lg-start" },
      [
        _c(
          "h4",
          {
            staticClass: "text-body-color",
            staticStyle: { "margin-bottom": "10px !important" },
          },
          [_vm._v("¿Eres talento digital y quieres unirte a Stemdo?")]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "text-body-color" }, [
          _vm._v("Únete a la comunidad stemdoer, te estamos buscando."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("footer", { attrs: { id: "footer" } }, [
      _c("div", { attrs: { id: "copyrights" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-md-3 text-center text-md-start",
                staticStyle: { color: "#606061" },
              },
              [_c("b", [_vm._v("Stemdo © 2024")])]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-7 text-center text-md-center copyright-links",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-around px-2" },
                  [
                    _c("a", { attrs: { href: "#" } }, [
                      _c("u", [_vm._v("Política de privacidad")]),
                    ]),
                    _vm._v(" "),
                    _c("a", { attrs: { href: "#" } }, [
                      _c("u", [_vm._v("Política de Cookies")]),
                    ]),
                    _vm._v(" "),
                    _c("a", { attrs: { href: "#" } }, [
                      _c("u", [_vm._v("Aviso Legal")]),
                    ]),
                    _vm._v(" "),
                    _c("a", { attrs: { href: "#" } }, [
                      _c("u", [_vm._v("Contacto")]),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 text-center text-md-end pb-4 pb-sm-0" },
              [
                _c("div", { staticClass: "d-flex justify-content-around" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.instagram.com/stemdo_io/?hl=es",
                        target: "”_blank”",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "logo-default",
                        attrs: {
                          srcset: "/content/images/instagram-icon.png",
                          src: "/content/images/instagram-icon.png",
                          alt: "Instagram icon",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.linkedin.com/company/stemdo/",
                        target: "”_blank”",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "logo-default",
                        attrs: {
                          srcset: "/content/images/linkedin-icon.png",
                          src: "/content/images/linkedin-icon.png",
                          alt: "Linkedin icon",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }