import { defineComponent, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { ButtonGroupModals } from '../../../buttonsGroup';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  components: {
    SButton,
    SModalCentered,
  },
  setup(props) {
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };

    const handleClose = () => {
      props.onClose(ButtonGroupModals.TR_CANCELLED);
    };

    return {
      customModalProps,
      handleClose,
    };
  },
});
