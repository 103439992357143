import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const hourlyRate = computed(() => {
      return props.teamRequest.stemdoerRates?.reduce((acc, sr) => acc + sr.rate, 0);
    });

    return {
      hourlyRate,
    };
  },
});
