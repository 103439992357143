import { defineComponent, inject, PropType, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { TeamRequest } from '@/shared/model/team-request.model';
import { ButtonGroupModals } from '../../../buttonsGroup';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true,
    },
    refreshTeamRequest: {
      type: Function,
      required: true,
    },
    onOpenModal: {
      type: Function,
      required: true,
    },
  },
  components: {
    SButton,
    SModalCentered,
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService');

    const validateRequest = () => {
      teamRequestService.changeStatus(props.teamRequest.id, RequestStatus.VALIDATED).then(() => {
        props.onOpenModal(ButtonGroupModals.TR_VALIDATED);
        props.refreshTeamRequest();
      });
    };

    const cancelRequest = () => {
      teamRequestService.changeStatus(props.teamRequest.id, RequestStatus.CANCELLED_BY_MANAGER).then(() => {
        props.onOpenModal(ButtonGroupModals.TR_CANCELLED);
        props.refreshTeamRequest();
      });
    };

    return {
      validateRequest,
      cancelRequest,
    };
  },
});
