export interface IDepartment {
  name?: string;
  technologyIds?: number[];
}

export class Department implements IDepartment {
  name?: string;
  technologyIds?: number[];

  constructor(data: IDepartment) {
    this.name = data.name;
    this.technologyIds = data.technologyIds;
  }

  static from(data: IDepartment): Department {
    return new Department(data);
  }
}

export class Departments extends Array<Department> {
  constructor(data: Department[]) {
    super(...data);
  }

  static from(data: Department[]): Departments {
    if (!data) return null;
    return new Departments(data.map(department => new Department(department)));
  }

  getTechnologiesIds = (): number[] => {
    return this.reduce((acc, department) => {
      return [...acc, ...department.technologyIds];
    }, []);
  };
}
