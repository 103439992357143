import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    search: {
      type: String as PropType<string>,
      required: true,
    },
  },
  emits: ['update:search'],
  setup(props, { emit }) {
    const updateSearch = (event: Event) => {
      const input = event.target as HTMLInputElement;
      emit('update:search', input.value);
    };

    return {
      updateSearch,
      search: props.search,
    };
  },
});
