import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/sections/admin/entities/entities.vue');

const Certification = () => import('@/sections/admin/entities/certification/certification.vue');
const CertificationUpdate = () => import('@/sections/admin/entities/certification/certification-update.vue');
const CertificationDetails = () => import('@/sections/admin/entities/certification/certification-details.vue');

const Vertical = () => import('@/sections/admin/entities/vertical/vertical.vue');
const VerticalUpdate = () => import('@/sections/admin/entities/vertical/vertical-update.vue');
const VerticalDetails = () => import('@/sections/admin/entities/vertical/vertical-details.vue');

const Cohort = () => import('@/sections/admin/entities/cohort/cohort.vue');
const CohortUpdate = () => import('@/sections/admin/entities/cohort/cohort-update.vue');
const CohortDetails = () => import('@/sections/admin/entities/cohort/cohort-details.vue');

const StemdoerDetails = () => import('@/sections/admin/entities/stemdoer/stemdoer-details.vue');
const StemdoerUpdate = () => import('@/sections/admin/entities/stemdoer/stemdoer-update.vue');
const Stemdoer = () => import('@/sections/admin/entities/stemdoer/stemdoer.vue');

const ClientDetails = () => import('@/sections/admin/entities/client/client-details.vue');
const ClientUpdate = () => import('@/sections/admin/entities/client/client-update.vue');
const Client = () => import('@/sections/admin/entities/client/client.vue');

const CompanyDetails = () => import('@/sections/admin/entities/company/company-details.vue');
const CompanyUpdate = () => import('@/sections/admin/entities/company/company-update.vue');
const Company = () => import('@/sections/admin/entities/company/company.vue');

const NotificationDetails = () => import('@/sections/admin/entities/notification/notification-details.vue');
const NotificationUpdate = () => import('@/sections/admin/entities/notification/notification-update.vue');
const Notification = () => import('@/sections/admin/entities/notification/notification.vue');

const TeamRequest = () => import('@/sections/admin/entities/team-request/team-request.vue');
const TeamRequestUpdate = () => import('@/sections/admin/entities/team-request/team-request-update.vue');
const TeamRequestDetails = () => import('@/sections/admin/entities/team-request/team-request-details.vue');

const ActivityLog = () => import('@/sections/admin/entities/activity-log/activity-log.vue');
const ActivityLogUpdate = () => import('@/sections/admin/entities/activity-log/activity-log-update.vue');
const ActivityLogDetails = () => import('@/sections/admin/entities/activity-log/activity-log-details.vue');

const CohortRequest = () => import('@/sections/admin/entities/cohort-request/cohort-request.vue');
const CohortRequestUpdate = () => import('@/sections/admin/entities/cohort-request/cohort-request-update.vue');
const CohortRequestDetails = () => import('@/sections/admin/entities/cohort-request/cohort-request-details.vue');

const StaffDetails = () => import('@/sections/admin/entities/staff/staff-details.vue');
const StaffUpdate = () => import('@/sections/admin/entities/staff/staff-update.vue');
const Staff = () => import('@/sections/admin/entities/staff/staff.vue');

const ClientRate = () => import('@/sections/admin/entities/client-rate/client-rate.vue');
const ClientRateUpdate = () => import('@/sections/admin/entities/client-rate/client-rate-update.vue');
const ClientRateDetails = () => import('@/sections/admin/entities/client-rate/client-rate-details.vue');

const Technology = () => import('@/sections/admin/entities/technology/technology.vue');
const TechnologyUpdate = () => import('@/sections/admin/entities/technology/technology-update.vue');
const TechnologyDetails = () => import('@/sections/admin/entities/technology/technology-details.vue');

const TechnologyTerm = () => import('@/sections/admin/entities/technology-term/technology-term.vue');
const TechnologyTermUpdate = () => import('@/sections/admin/entities/technology-term/technology-term-update.vue');
const TechnologyTermDetails = () => import('@/sections/admin/entities/technology-term/technology-term-details.vue');

const SoftSkill = () => import('@/sections/admin/entities/soft-skill/soft-skill.vue');
const SoftSkillUpdate = () => import('@/sections/admin/entities/soft-skill/soft-skill-update.vue');
const SoftSkillDetails = () => import('@/sections/admin/entities/soft-skill/soft-skill-details.vue');

const NoResultFiltersReport = () => import('@/sections/admin/entities/no-result-filters-report/no-result-filters-report.vue');
const NoResultFiltersReportUpdate = () => import('@/sections/admin/entities/no-result-filters-report/no-result-filters-report-update.vue');
const NoResultFiltersReportDetails = () =>
  import('@/sections/admin/entities/no-result-filters-report/no-result-filters-report-details.vue');

const ClientDepartment = () => import('@/sections/admin/entities/client-department/client-department.vue');
const ClientDepartmentUpdate = () => import('@/sections/admin/entities/client-department/client-department-update.vue');
const ClientDepartmentDetails = () => import('@/sections/admin/entities/client-department/client-department-details.vue');

// jhipster-needle-add-entity-to-router - JHipster will add entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'vertical',
      name: 'Vertical',
      component: Vertical,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'vertical/new',
      name: 'VerticalCreate',
      component: VerticalUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'vertical/:verticalId/edit',
      name: 'VerticalEdit',
      component: VerticalUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'vertical/:verticalId/view',
      name: 'VerticalView',
      component: VerticalDetails,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'stemdoer',
      name: 'Stemdoer',
      component: Stemdoer,
      meta: { authorities: [Authority.ADMIN, Authority.RRHH, Authority.GESTOR] }
    },
    {
      path: 'stemdoer/new',
      name: 'StemdoerCreate',
      component: StemdoerUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'stemdoer/:stemdoerId/edit',
      name: 'StemdoerEdit',
      component: StemdoerUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.STEMDOER] }
    },
    {
      path: 'stemdoer/:stemdoerId/view',
      name: 'StemdoerView',
      component: StemdoerDetails,
      meta: { authorities: [Authority.ADMIN, Authority.RRHH, Authority.GESTOR] }
    },
    {
      path: 'certification',
      name: 'Certification',
      component: Certification,
      meta: { authorities: [Authority.ADMIN, Authority.RRHH] }
    },
    {
      path: 'certification/new',
      name: 'CertificationCreate',
      component: CertificationUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'certification/:certificationId/edit',
      name: 'CertificationEdit',
      component: CertificationUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.RRHH] }
    },
    {
      path: 'certification/:certificationId/view',
      name: 'CertificationView',
      component: CertificationDetails,
      meta: { authorities: [Authority.ADMIN, Authority.RRHH] }
    },
    {
      path: 'cohort',
      name: 'Cohort',
      component: Cohort,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'cohort/new',
      name: 'CohortCreate',
      component: CohortUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'cohort/:cohortId/edit',
      name: 'CohortEdit',
      component: CohortUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'cohort/:cohortId/view',
      name: 'CohortView',
      component: CohortDetails,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'client',
      name: 'Client',
      component: Client,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'client/new',
      name: 'ClientCreate',
      component: ClientUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'client/:clientId/edit',
      name: 'ClientEdit',
      component: ClientUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'client/:clientId/view',
      name: 'ClientView',
      component: ClientDetails,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'company',
      name: 'Company',
      component: Company,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'company/new',
      name: 'CompanyCreate',
      component: CompanyUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'company/:companyId/edit',
      name: 'CompanyEdit',
      component: CompanyUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'company/:companyId/view',
      name: 'CompanyView',
      component: CompanyDetails,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'team-request',
      name: 'TeamRequest',
      component: TeamRequest,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'team-request/new',
      name: 'TeamRequestCreate',
      component: TeamRequestUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'team-request/:teamRequestId/edit',
      name: 'TeamRequestEdit',
      component: TeamRequestUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    // {
    //   path: 'team-request/:teamRequestId/view',
    //   name: 'TeamRequestView',
    //   component: TeamRequestDetails,
    //   meta: { authorities: [Authority.ADMIN, Authority.RRHH, Authority.GESTOR] },
    // },
    {
      path: 'activity-log',
      name: 'ActivityLog',
      component: ActivityLog,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'activity-log/new',
      name: 'ActivityLogCreate',
      component: ActivityLogUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'activity-log/:activityLogId/edit',
      name: 'ActivityLogEdit',
      component: ActivityLogUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'activity-log/:activityLogId/view',
      name: 'ActivityLogView',
      component: ActivityLogDetails,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'notification',
      name: 'Notification',
      component: Notification,
      meta: { authorities: [Authority.ADMIN, Authority.RRHH] }
    },
    {
      path: 'notification/new',
      name: 'NotificationCreate',
      component: NotificationUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'notification/:notificationId/edit',
      name: 'NotificationEdit',
      component: NotificationUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'notification/:notificationId/view',
      name: 'NotificationView',
      component: NotificationDetails,
      meta: { authorities: [Authority.ADMIN, Authority.RRHH] }
    },
    {
      path: 'cohort-request',
      name: 'CohortRequest',
      component: CohortRequest,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'cohort-request/new',
      name: 'CohortRequestCreate',
      component: CohortRequestUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'cohort-request/:cohortRequestId/edit',
      name: 'CohortRequestEdit',
      component: CohortRequestUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'cohort-request/:cohortRequestId/view',
      name: 'CohortRequestView',
      component: CohortRequestDetails,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'staff',
      name: 'Staff',
      component: Staff,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'staff/new',
      name: 'StaffCreate',
      component: StaffUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'staff/:staffId/edit',
      name: 'StaffEdit',
      component: StaffUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'staff/:staffId/view',
      name: 'StaffView',
      component: StaffDetails,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'client-rate',
      name: 'ClientRate',
      component: ClientRate,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'client-rate/new',
      name: 'ClientRateCreate',
      component: ClientRateUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'client-rate/:clientRateId/edit',
      name: 'ClientRateEdit',
      component: ClientRateUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'client-rate/:clientRateId/view',
      name: 'ClientRateView',
      component: ClientRateDetails,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'technology',
      name: 'Technology',
      component: Technology,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'technology/new',
      name: 'TechnologyCreate',
      component: TechnologyUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'technology/:technologyId/edit',
      name: 'TechnologyEdit',
      component: TechnologyUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'technology/:technologyId/view',
      name: 'TechnologyView',
      component: TechnologyDetails,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'technology-term',
      name: 'TechnologyTerm',
      component: TechnologyTerm,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'technology-term/new',
      name: 'TechnologyTermCreate',
      component: TechnologyTermUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'technology-term/:technologyTermId/edit',
      name: 'TechnologyTermEdit',
      component: TechnologyTermUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'technology-term/:technologyTermId/view',
      name: 'TechnologyTermView',
      component: TechnologyTermDetails,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'soft-skill',
      name: 'SoftSkill',
      component: SoftSkill,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'soft-skill/new',
      name: 'SoftSkillCreate',
      component: SoftSkillUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'soft-skill/:softSkillId/edit',
      name: 'SoftSkillEdit',
      component: SoftSkillUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'soft-skill/:softSkillId/view',
      name: 'SoftSkillView',
      component: SoftSkillDetails,
      meta: { authorities: [Authority.ADMIN, Authority.GESTOR] }
    },
    {
      path: 'no-result-filters-report',
      name: 'NoResultFiltersReport',
      component: NoResultFiltersReport,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'no-result-filters-report/new',
      name: 'NoResultFiltersReportCreate',
      component: NoResultFiltersReportUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'no-result-filters-report/:NoResultFiltersReportId/edit',
      name: 'NoResultFiltersReportEdit',
      component: NoResultFiltersReportUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'no-result-filters-report/:NoResultFiltersReportId/view',
      name: 'NoResultFiltersReportView',
      component: NoResultFiltersReportDetails,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'client-department',
      name: 'ClientDepartment',
      component: ClientDepartment,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'client-department/new',
      name: 'ClientDepartmentCreate',
      component: ClientDepartmentUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'client-department/:clientDepartmentId/edit',
      name: 'ClientDepartmentEdit',
      component: ClientDepartmentUpdate,
      meta: { authorities: [Authority.ADMIN] }
    },
    {
      path: 'client-department/:clientDepartmentId/view',
      name: 'ClientDepartmentView',
      component: ClientDepartmentDetails,
      meta: { authorities: [Authority.ADMIN] }
    }
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ]
};
