import { computed, defineComponent, PropType, ref, watch } from 'vue';
import CheckoutGroup from './groups/manager/checkout-group/CheckoutGroup.vue';
import ValidatedGroup from './groups/client/validated-group/ValidatedGroup.vue';
import SavedGroup from './groups/client/saved-group/SavedGroup.vue';
import CheckoutConfirmationModal from './modal/manager/checkout-confirmation-modal/CheckoutConfirmationModal.vue';
import { TeamRequest } from '@/shared/model/team-request.model';
import { ExtendedTeamRequestStatus } from '../enum/extendedTeamRequestStatus';
import ConfirmationModal from './modal/confirmation-modal/ConfirmationModal.vue';

export enum ButtonGroupModals {
  MANAGER_CHECKOUT_CONFIRMATION = 'MANAGER_CHECKOUT_CONFIRMATION',
  CONFIRMATION = 'CONFIRMATION'
}

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    teamRequestStatus: {
      type: String as PropType<ExtendedTeamRequestStatus>
    }
  },
  components: {
    CheckoutGroup,
    ConfirmationModal,
    CheckoutConfirmationModal,
    ValidatedGroup,
    SavedGroup
  },
  setup(props) {
    const mutableTeamRequest = ref<TeamRequest>({ ...props.teamRequest });
    const isConfirmationModalOpen = ref(false);
    const isManagerCheckoutConfirmationModalOpen = ref(false);

    watch(
      () => props.teamRequest,
      newVal => {
        mutableTeamRequest.value = { ...newVal };
      },
      { deep: true }
    );

    const closeModal = (type: ButtonGroupModals) => {
      switch (type) {
        case ButtonGroupModals.CONFIRMATION:
          isConfirmationModalOpen.value = false;
          break;
        case ButtonGroupModals.MANAGER_CHECKOUT_CONFIRMATION:
          isManagerCheckoutConfirmationModalOpen.value = false;
          break;
        default:
          throw new Error('Unknown modal type');
      }
    };

    const openModal = (type: ButtonGroupModals, data?: any) => {
      switch (type) {
        case ButtonGroupModals.CONFIRMATION:
          isConfirmationModalOpen.value = true;
          break;
        case ButtonGroupModals.MANAGER_CHECKOUT_CONFIRMATION:
          mutableTeamRequest.value.id = data.id;
          isManagerCheckoutConfirmationModalOpen.value = true;
          break;
        default:
          throw new Error('Unknown modal type');
      }
    };

    const currentComponent = computed(() => {
      switch (props.teamRequestStatus) {
        case ExtendedTeamRequestStatus.MANAGER_AND_CHECKOUT:
          return 'CheckoutGroup';
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED:
          return 'ValidatedGroup';
        case ExtendedTeamRequestStatus.CLIENT_AND_SAVED:
          return 'SavedGroup';
        default:
          return null;
      }
    });

    return { mutableTeamRequest, isConfirmationModalOpen, isManagerCheckoutConfirmationModalOpen, currentComponent, openModal, closeModal };
  }
});
