import { render, staticRenderFns } from "./SwitchEnglish.vue?vue&type=template&id=2e180ba7&scoped=true"
import script from "./switchEnglish.ts?vue&type=script&lang=ts&external"
export * from "./switchEnglish.ts?vue&type=script&lang=ts&external"
import style0 from "./SwitchEnglish.vue?vue&type=style&index=0&id=2e180ba7&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e180ba7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e180ba7')) {
      api.createRecord('2e180ba7', component.options)
    } else {
      api.reload('2e180ba7', component.options)
    }
    module.hot.accept("./SwitchEnglish.vue?vue&type=template&id=2e180ba7&scoped=true", function () {
      api.rerender('2e180ba7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/sections/shared/explore/more-filters/filter/switch-english/SwitchEnglish.vue"
export default component.exports