var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 mb-8 pl-0 pt-0",
      attrs: { id: "candidate-list", fluid: "" },
    },
    [
      _c("v-data-table", {
        staticClass: "remove-box-shadow candidate-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.candidates,
          "hide-default-footer": "",
          "disable-pagination": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item.fullName",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  {
                    staticClass: "pl-6",
                    on: {
                      click: function ($event) {
                        return _vm.openStemdoerDetail(item)
                      },
                    },
                  },
                  [
                    _c("SAvatarStemdoer", {
                      attrs: { stemdoId: item.stemdoId, size: "21px" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-1" }, [
                      _vm._v(" " + _vm._s(item.fullName)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.rate",
            fn: function ({ item }) {
              return [_vm._v(" " + _vm._s(item.rate) + "€ ")]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("SModalRight", {
        attrs: {
          maxWidth: "475px",
          title: _vm.$t("candidate.cadidateModalDetail.title"),
          isOpen: _vm.isOpenDetailModal,
          closeModal: _vm.closeStemdoerDetailModal,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _vm.candidateSelected
                  ? _c("CandidatesDetail", {
                      attrs: { candidateSelected: _vm.candidateSelected },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [_c("p")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }