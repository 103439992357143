import { render, staticRenderFns } from "./button.component.vue?vue&type=template&id=7524d757"
import script from "./button.component.ts?vue&type=script&lang=ts&external"
export * from "./button.component.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7524d757')) {
      api.createRecord('7524d757', component.options)
    } else {
      api.reload('7524d757', component.options)
    }
    module.hot.accept("./button.component.vue?vue&type=template&id=7524d757", function () {
      api.rerender('7524d757', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/stemdo-components/button.component.vue"
export default component.exports