var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { width: "fit-content" } }, [
    this.conf.btnType == "bootstrapBtn"
      ? _c(
          "button",
          { class: this.conf.class, on: { click: this.conf.function } },
          [_vm._v("\n    " + _vm._s(this.conf.text) + "\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    this.conf.btnType == "stemdoBtn"
      ? _c(
          "div",
          {
            staticClass: "stemdoBtn",
            attrs: { "data-cy": "generalButtonStemdo" },
            on: { click: this.conf.function },
          },
          [
            _c("span", [_vm._v(_vm._s(this.conf.text))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "svgContainer" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "fa-solid fa-arrow-right" },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    this.conf.btnType == "backBtn"
      ? _c(
          "button",
          {
            staticClass: "btn btn-info",
            attrs: { type: "submit", "data-cy": "entityDetailsBackButton" },
            on: { click: () => _vm.$router.go(-1) },
          },
          [
            _c("font-awesome-icon", { attrs: { icon: "arrow-left" } }),
            _vm._v(" "),
            _c("span", {
              domProps: { textContent: _vm._s(_vm.$t("entity.action.back")) },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }