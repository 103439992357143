import axios from 'axios';
import { ref, watch } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import router from '@/router';
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import useVuelidate from '@vuelidate/core';

export default {
  mixins: [validationMixin],

  validations: {
    resetAccount: {
      email: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(254),
        email,
      },
    },
  },

  components: {
    SButton,
  },

  setup(_, { emit }) {
    const showModal = ref(false);
    const success = ref(null);
    const error = ref(null);
    const isFormInvalid = ref<boolean | null>(null);
    const v$ = useVuelidate();
    const resetAccount = ref({
      email: null,
    });

    watch([() => resetAccount.value.email], () => {
      isFormInvalid.value = null;
    });

    const validateForm = () => {
      v$.value.$touch();
      isFormInvalid.value = v$.value.resetAccount.$invalid;
      if (!isFormInvalid.value) {
        requestReset();
      }
    };

    const gotoLogin = () => {
      router.push({
        name: 'login',
        params: { passwordResetSuccess: success.value },
      });
    };

    const requestReset = async (): Promise<void> => {
      error.value = null;
      try {
        await axios.post('api/account/reset-password/init', resetAccount.value.email, {
          headers: {
            'content-type': 'text/plain',
          },
        });
        success.value = true;
        emit('sms', {
          successRestart: success.value,
        });
        emit('add', 0);
        gotoLogin();
      } catch {
        success.value = false;
        error.value = 'ERROR';
        emit('sms', {
          errorRestart: error.value,
        });
      }
    };

    return {
      showModal,
      success,
      error,
      resetAccount,
      isFormInvalid,
      gotoLogin,
      validateForm,
    };
  },
};
