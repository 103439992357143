var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0 custom-container" },
    [
      _c(
        "v-row",
        {
          staticClass: "d-flex py-0 px-4",
          staticStyle: { "min-height": "80px" },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-end", attrs: { cols: "12" } },
            [
              _c("span", {
                staticClass: "text-subtitle-4 title-span",
                domProps: { textContent: _vm._s(_vm.$t("requests.client")) },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-autocomplete", {
                staticClass: "custom-v-autocomplete",
                attrs: {
                  placeholder: _vm.$t("explore.select-client"),
                  items: _vm.clients,
                  "item-text": (item) => `${item.name} ${item.surname}`,
                  "return-object": "",
                  labelDisplaySelection: true,
                  inputHeight: 45,
                  disabled: _vm.isDisabled,
                  dense: "",
                  flat: "",
                  solo: "",
                },
                on: { input: _vm.onClientSelected },
                model: {
                  value: _vm.selectedItem,
                  callback: function ($$v) {
                    _vm.selectedItem = $$v
                  },
                  expression: "selectedItem",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }