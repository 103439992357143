<template>
  <TeamRequests :openSuccessPopUp="openPopUp ? true : false" />
</template>

<script setup>
import TeamRequests from '@/sections/client/team-requests/TeamRequests.vue';
import { useRouter } from '@/plugins/router';

const router = useRouter();
const openPopUp = router.currentRoute.query['saved-success']?.toString();
router.replace({ query: { ...router.currentRoute.query, 'saved-success': undefined } });
</script>
