/* eslint-disable prettier/prettier */
import { useStore } from '@/plugins/vuex';
import { defineComponent, inject } from 'vue';
import Filters from '@/sections/shared/explore/model/Filters';
import NoResultFiltersReportService from '@/sections/admin/entities/no-result-filters-report/no-result-filters-report.service';
import { EntityEnum } from '@/shared/model/enumerations/entity-enum.model';
import { INoResultFiltersReport } from '@/shared/model/no-result-filters-report.model';

export default defineComponent({
  props: {},
  setup() {
    const store = useStore();
    const noResultFiltersReportService: NoResultFiltersReportService = inject('noResultFiltersReportService');

    const notifyAndCleanFilters = () => {
      const filters: Filters = store.getters['filterStore/filters'];
      const standardFilters = filters.toStandardFilter();
      const jsonFilters: INoResultFiltersReport = {
        entity: EntityEnum.STEMDOERS,
        filterReports: standardFilters,
      };

      noResultFiltersReportService
        .create(jsonFilters)
        .then(() => {})
        .catch(() => {
          console.error('Error saving filters');
        });

      store.commit('filterStore/clearToInitState');
    };

    return { notifyAndCleanFilters };
  },
});
