var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { id: "stemdoerList", fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                _vm._l(_vm.stemdoers, function (stemdoer) {
                  return _c(
                    "v-col",
                    {
                      key: stemdoer.id,
                      attrs: { xs: "12", sm: "12", md: "12", lg: "6", xl: "6" },
                    },
                    [
                      _c("StemdoerCard", {
                        attrs: {
                          stemdoer: stemdoer,
                          addStemdoerToCart: _vm.addStemdoerToCart,
                          rates: _vm.rates,
                          openStemdoerDetailModal: _vm.openStemdoerDetailModal,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ModalStemdoerDetail", {
        attrs: {
          isOpen: _vm.isStemdoerDetailModalOpen,
          onClose: _vm.closeStemdoerDetailModal,
          stemdoer: _vm.selectedStemdoer,
          rates: _vm.rates,
          onAddToCart: _vm.addStemdoerToCart,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }