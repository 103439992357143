import { IStemdoer } from '@/shared/model/stemdoer.model';
import Filter, { IAtocompleteMultipleProps, IAtocompleteMultipleRatingProps, IAtocompleteProps, Operators } from './Filter';
import { ITechnologyScore } from '@/shared/model/technology-score.model';
import Filters, { FilterFields } from './Filters';
import { IRangeSliderStepsRateState } from '@/components/stemdo-components/s-range-slider-steps-rate/sRangeSliderStepsRate';

export const filterStemdoers = (filters: Filters, stemdoers: IStemdoer[]): IStemdoer[] => {
  return stemdoers.filter(s => {
    return (
      filterStemdoerByCompetency(filters, s) &&
      filterStemdoerByCertifications(filters, s) &&
      filterStemdoerBySoftSkills(filters, s) &&
      filterStemdoerByRate(filters, s) &&
      filterStemdoerByEnglish(filters, s) &&
      filterStemdoerByAvailability(filters, s) &&
      filterStemdoerByExperienceLevel(filters, s)
    );
  });
};

export const filterStemdoersClosestMatch = (filters: Filters, stemdoers: IStemdoer[]): IStemdoer[] => {
  const filteredStemdoers = stemdoers.filter(s => {
    return filterStemdoerByCompetencyClosestMatch(filters, s) && filterStemdoerByAvailability(filters, s);
  });
  if (filteredStemdoers.length === 0) {
    return stemdoers.filter(s => {
      return filterStemdoerByAvailability(filters, s);
    });
  }
  return orderStemdoersByCompetency(filters, filteredStemdoers);
};

const filterStemdoerByTechnology = (filters: Filters, stemdoer: IStemdoer): boolean => {
  const techFilters: Filter[] = filters.getFiltersByField(FilterFields.Technology);
  if (techFilters.length === 0) {
    return true;
  }
  const stemdoerVerticals: any[] = stemdoer.verticals;
  if (stemdoerVerticals.length === 0) {
    return false;
  }
  let passFilter = true;
  for (let i = 0; passFilter && i < techFilters.length; i++) {
    const filter = techFilters[i];
    const { operator, value } = filter.props as IAtocompleteProps;
    if (operator === Operators.Equal) {
      const index = stemdoerVerticals.findIndex(v => v.name === value.id);
      if (index === -1) {
        passFilter = false;
        break;
      }
    }
  }
  return passFilter;
};

const filterStemdoerByCompetency = (filters: Filters, stemdoer: IStemdoer): boolean => {
  const competencyFilters: Filter[] = filters.getFiltersByField(FilterFields.Competency);
  if (competencyFilters.length === 0) {
    return true;
  }
  const technologyScores: ITechnologyScore[] = stemdoer.technologyScores;
  if (!technologyScores || technologyScores.length === 0) return false;

  let passFilter = true;
  for (let i = 0; passFilter && i < competencyFilters.length; i++) {
    const filter = competencyFilters[i];
    const { operator, value } = filter.props as IAtocompleteMultipleRatingProps;
    if (operator[0] === Operators.Equal) {
      const i = technologyScores.findIndex(ts => ts.idTechnology === value[0].id);
      if (i === -1) {
        passFilter = false;
        break;
      }
      if (operator[1] === Operators.GreaterThanOrEqual) {
        if (!(value[1] === 1 || (value[1] === 2 && technologyScores[i].score > 3) || (value[1] === 3 && technologyScores[i].score >= 6))) {
          passFilter = false;
          break;
        }
      }
    }
  }
  return passFilter;
};

const filterStemdoerByCompetencyClosestMatch = (filters: Filters, stemdoer: IStemdoer): boolean => {
  const competencyFilters: Filter[] = filters.getFiltersByField(FilterFields.Competency);
  if (competencyFilters.length === 0) {
    return true;
  }
  const technologyScores: ITechnologyScore[] = stemdoer.technologyScores;
  if (!technologyScores || technologyScores.length === 0) return false;

  let passFilter = true;
  for (let i = 0; passFilter && i < competencyFilters.length; i++) {
    const filter = competencyFilters[i];
    const { operator, value } = filter.props as IAtocompleteMultipleRatingProps;
    if (operator[0] === Operators.Equal) {
      const i = technologyScores.findIndex(ts => ts.idTechnology === value[0].id);
      if (i === -1) {
        passFilter = false;
        break;
      }
    }
  }
  return passFilter;
};

const orderStemdoersByCompetency = (filters: Filters, stemdoers: IStemdoer[]): IStemdoer[] => {
  const stemdoersCopy: IStemdoer[] = stemdoers.map(a => ({ ...a }));
  const competencyFilters: Filter[] = filters.getFiltersByField(FilterFields.Competency);
  stemdoersCopy.sort((a, b) => {
    const aTotalScore = a.technologyScores.reduce((acc, techScore) => {
      const idTech = techScore.idTechnology;
      const filter = competencyFilters.find(f => {
        const { value } = f.props as IAtocompleteMultipleRatingProps;
        return value[0].id === idTech;
      });
      if (filter === undefined) return acc;
      return acc + techScore.score;
    }, 0);
    const bTotalScore = b.technologyScores.reduce((acc, techScore) => {
      const idTech = techScore.idTechnology;
      const filter = competencyFilters.find(f => {
        const { value } = f.props as IAtocompleteMultipleRatingProps;
        return value[0].id === idTech;
      });
      if (filter === undefined) return acc;
      return acc + techScore.score;
    }, 0);
    return bTotalScore - aTotalScore;
  });
  return stemdoersCopy;
};

const filterStemdoerByCertifications = (filters: Filters, stemdoer: IStemdoer): boolean => {
  const certFilters: Filter[] = filters.getFiltersByField(FilterFields.Certification);
  if (certFilters.length === 0) {
    return true;
  }
  if (!stemdoer.certifications || stemdoer.certifications.length === 0) return false;
  const stemdoerCerts: any[] = stemdoer.certifications;
  if (stemdoerCerts.length === 0) {
    return false;
  }
  let passFilter = true;
  for (let i = 0; passFilter && i < certFilters.length; i++) {
    const filter = certFilters[i];
    const { operator, value } = filter.props as IAtocompleteMultipleProps;
    if (operator === Operators.Equal) {
      const index = stemdoerCerts.findIndex(c => c.id === value.id);
      if (index === -1) {
        passFilter = false;
        break;
      }
    }
  }
  return passFilter;
};

const filterStemdoerBySoftSkills = (filters: Filters, stemdoer: IStemdoer): boolean => {
  const softSkillsFilters: Filter[] = filters.getFiltersByField(FilterFields.SoftSkill);
  if (softSkillsFilters.length === 0) {
    return true;
  }
  const stemdoerSoftSkills: number[] = stemdoer.softSkills;
  if (stemdoerSoftSkills.length === 0) {
    return false;
  }

  let passFilter = true;
  for (let i = 0; passFilter && i < softSkillsFilters.length; i++) {
    const filter = softSkillsFilters[i];
    const { operator, value } = filter.props as IAtocompleteMultipleProps;
    if (operator === Operators.Equal) {
      const index = stemdoerSoftSkills.findIndex(s => s === +value.id);
      if (index === -1) {
        passFilter = false;
        break;
      }
    }
  }
  return passFilter;
};

const filterStemdoerByRate = (filters: Filters, stemdoer: IStemdoer): boolean => {
  const rateFilters: Filter[] = filters.getFiltersByField(FilterFields.Rate);
  if (rateFilters.length === 0) {
    return true;
  }
  const rateFilter: Filter = rateFilters[0];

  const rate = stemdoer.rate;
  if (rateFilter.props.operator === Operators.Between) {
    const rateRange = rateFilter.props.value as IRangeSliderStepsRateState;
    if (rate < rateRange[0] || rate > rateRange[1]) {
      return false;
    }
  }

  return true;
};

const filterStemdoerByEnglish = (filters: Filters, stemdoer: IStemdoer): boolean => {
  const langFilters: Filter[] = filters.getFiltersByField(FilterFields.Language);
  if (langFilters.length === 0) {
    return true;
  }
  const langFilter: Filter = langFilters[0];
  const englishLevel = stemdoer.english;

  if (langFilter.props.value === true) {
    if (englishLevel === 'B1' || englishLevel === 'B2' || englishLevel === 'C1' || englishLevel === 'C2') {
      return true;
    }
  }
  return false;
};

const filterStemdoerByAvailability = (filters: Filters, stemdoer: IStemdoer): boolean => {
  const availFilters: Filter[] = filters.getFiltersByField(FilterFields.Available);
  if (availFilters.length === 0) {
    return true;
  }
  const availFilter: Filter = availFilters[0];
  const availability = stemdoer.availability.available;
  const canBeAddedToCartToCart = stemdoer.canBeAddedToCart;

  if (availFilter.props.value === false) {
    if (availability === true || canBeAddedToCartToCart === true) {
      return true;
    }
    return false;
  }
  return true;
};

const filterStemdoerByExperienceLevel = (filters: Filters, stemdoer: IStemdoer): boolean => {
  const expFilters: Filter[] = filters.getFiltersByField(FilterFields.ExperienceLevel);
  if (expFilters.length === 0) {
    return true;
  }
  const expFilter: Filter = expFilters[0];
  const seniority = stemdoer.seniority;
  if (expFilter.props.value === 0 && seniority === 'JUNIOR') {
    return true;
  }
  if (expFilter.props.value === 1 && seniority === 'MID_SENIOR') {
    return true;
  }
  if (expFilter.props.value === 2 && seniority === 'SENIOR') {
    return true;
  }
  return false;
};
