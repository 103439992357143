var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [_c(_vm.componentType(), { tag: "component", attrs: { conf: this.conf } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }