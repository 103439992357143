import { defineComponent, inject, onMounted, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { ButtonGroupModals } from '../../../buttonsGroup';
import { TeamRequest } from '@/shared/model/team-request.model';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { useRouter } from '@/plugins/router';
import SModalTeamRequestConfirmation from '@/components/stemdo-components/s-modal-team-request-confirmation/SModalTeamRequestConfirmation.vue';
import { Stemdoers } from '@/shared/model/stemdoer.model';
import SModalStemdoerNotAvailable from '@/components/stemdo-components/s-modal-stemdoer-not-available/SModalStemdoerNotAvailable.vue';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    refreshTeamRequest: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onSuccess: {
      type: Function,
      required: true
    },
    onError: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton,
    SModalCentered,
    SModalTeamRequestConfirmation,
    SModalStemdoerNotAvailable
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };
    const openFormModal = ref(props.isOpen);
    const openApproveModal = ref(false);
    const router = useRouter();
    const isStemdoersNotAvailableModalOpen = ref(false);
    const stemdoersNotAvailables = ref<Stemdoers>(Stemdoers.empty());

    const handleClose = () => {
      props.onClose(ButtonGroupModals.SAVED_TO_PENDING_TR);
    };

    const openFormModalHandler = () => {
      openFormModal.value = true;
    };

    const closeFormModalHandler = () => {
      openFormModal.value = false;
    };

    const openStemdoersNotAvailableModal = () => {
      isStemdoersNotAvailableModalOpen.value = true;
    };

    const closeStemdoersNotAvailableModal = () => {
      isStemdoersNotAvailableModalOpen.value = false;
    };

    const closeApproveModalHandler = () => {
      openApproveModal.value = false;
      router.push({
        name: 'teamRequestsClient'
      });
    };

    const onClickFormModal = (teamRequestName: string, initDate: string, endDate: string, jobDescription: string) => {
      const teamRequestMutable = { ...props.teamRequest };
      teamRequestMutable.name = teamRequestName;
      teamRequestMutable.initDate = new Date(initDate);
      teamRequestMutable.endDate = new Date(endDate);
      teamRequestMutable.jobDescription = jobDescription;
      teamRequestMutable.removeNotAvailablesStemdoer();
      savedToPendingTeamRequest(teamRequestMutable);
    };

    const savedToPendingTeamRequest = (teamRequest: TeamRequest) => {
      if (props.teamRequest.status === RequestStatus.SAVED) {
        teamRequestService
          .blockAndPartialUpdate(teamRequest)
          .then(() => {
            handleClose();
            openApproveModal.value = true;
            props.onSuccess('teamRequestDetail.popUp.teamRequestSent');
          })
          .catch(error => {
            if (error.response.status === 400) {
              stemdoersNotAvailables.value = error.response.data.stemdoerRates.map(item => item.stemdoer);
              handleClose();
              openStemdoersNotAvailableModal();
              props.refreshTeamRequest();
            } else {
              props.onError();
            }
          });
      }
    };

    return {
      customModalProps,
      openFormModal,
      openApproveModal,
      isStemdoersNotAvailableModalOpen,
      stemdoersNotAvailables,
      handleClose,
      openFormModalHandler,
      closeFormModalHandler,
      closeApproveModalHandler,
      onClickFormModal,
      openStemdoersNotAvailableModal,
      closeStemdoersNotAvailableModal
    };
  }
});
