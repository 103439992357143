var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("SModalCentered", {
    attrs: {
      title: _vm.$t("checkout.modal.manager.validated.title"),
      isOpen: _vm.isOpen,
      closeModal: _vm.handleClose,
      customProps: _vm.customModalProps,
    },
    scopedSlots: _vm._u([
      {
        key: "title-append",
        fn: function () {
          return [
            _c(
              "v-tooltip",
              {
                attrs: { right: "", "content-class": "primary tooltip-right" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-img",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "link-button ml-3",
                                attrs: {
                                  width: 22,
                                  src: "/content/svgs/link-2-outline1.svg",
                                },
                                on: { click: _vm.copyToClipboard },
                              },
                              "v-img",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("checkout.modal.manager.validated.share"))
                  ),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "text-body-1" }, [
              _vm._v(_vm._s(_vm.$t("checkout.modal.manager.validated.body"))),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("SButton", {
              attrs: {
                action: _vm.handleClose,
                sText: _vm.$t("checkout.modal.manager.validated.button"),
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }