import { defineComponent, inject, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { ButtonGroupModals } from '../../../buttonsGroup';
import { TeamRequest } from '@/shared/model/team-request.model';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    refreshTeamRequest: {
      type: Function,
      required: true
    },
    onSuccess: {
      type: Function,
      required: true
    },
    onError: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton,
    SModalCentered
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };
    const isSavedToPendingTRModalOpen = ref(false);
    const isDisabled = ref(false);

    const handleClose = () => {
      props.onClose(ButtonGroupModals.ACCEPT_TR_ONLY_AVAILABLES);
    };

    const acceptTeamRequestWithoutStemdoerBlocked = () => {
      const teamRequestMutable = new TeamRequest(props.teamRequest);
      teamRequestMutable.removeNotAvailablesStemdoer();

      teamRequestService
        .approveAndPartialUpdate(teamRequestMutable)
        .then(() => {
          handleClose();
          props.refreshTeamRequest();
          props.onSuccess('teamRequestDetail.popUp.teamRequestAccepted');
        })
        .catch(() => {
          props.onError();
        });
    };
    const closeModal = () => {
      isSavedToPendingTRModalOpen.value = false;
    };

    return {
      customModalProps,
      isSavedToPendingTRModalOpen,
      isDisabled,
      closeModal,
      handleClose,
      acceptTeamRequestWithoutStemdoerBlocked
    };
  }
});
