import { render, staticRenderFns } from "./SendProposalModal.vue?vue&type=template&id=2b8f3a0a"
import script from "./sendProposalModal.ts?vue&type=script&lang=ts&external"
export * from "./sendProposalModal.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b8f3a0a')) {
      api.createRecord('2b8f3a0a', component.options)
    } else {
      api.reload('2b8f3a0a', component.options)
    }
    module.hot.accept("./SendProposalModal.vue?vue&type=template&id=2b8f3a0a", function () {
      api.rerender('2b8f3a0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/sections/shared/team-request-detail/buttons-group/modal/manager/sendProposal/SendProposalModal.vue"
export default component.exports