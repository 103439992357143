import { IStemdoer, Stemdoer } from '@/shared/model/stemdoer.model';
import { IRate, Rates } from './rate.model';

export interface IStemdoerRate {
  rate?: number;
  stemdoer?: IStemdoer;
  stemdoId?: string;
}

export class StemdoerRate implements IStemdoerRate {
  rate?: number;
  stemdoer?: Stemdoer;
  stemdoId?: string;

  constructor(data: IStemdoerRate) {
    this.rate = data?.rate;
    this.stemdoer = data?.stemdoer ? new Stemdoer(data.stemdoer) : undefined;
    this.stemdoId = data.stemdoId;
  }

  static empty = () => {
    return new StemdoerRate({
      rate: undefined,
      stemdoer: undefined,
      stemdoId: undefined
    });
  };

  static from = (data: IStemdoerRate[]): StemdoerRate[] => {
    return data.map(stemdoerRate => new StemdoerRate(stemdoerRate));
  };

  istemdoerAvailable = () => {
    return this.stemdoer?.isAvailable();
  };

  setRate = (clientRates: Rates) => {
    this.rate = clientRates.getBySeniority(this.stemdoer?.seniority).value;
  };
}

export class StemdoerRates extends Array<StemdoerRate> {
  constructor(data: StemdoerRate[]) {
    super(...data);
  }

  static empty = () => {
    return new StemdoerRates([]);
  };

  static from = (data: IStemdoerRate[]): StemdoerRates => {
    return new StemdoerRates(data.map(stemdoerRate => new StemdoerRate(stemdoerRate)));
  };

  areAllStemdoersAvailable = (): boolean => {
    return this.every(stemdoerRate => stemdoerRate.istemdoerAvailable());
  };

  getAvailableStemdoers = (): StemdoerRates => {
    return new StemdoerRates(this.filter(stemdoerRate => stemdoerRate.istemdoerAvailable()));
  };

  getNotAvailableStemdoers = (): StemdoerRates => {
    return new StemdoerRates(this.filter(stemdoerRate => !stemdoerRate.istemdoerAvailable()));
  };

  findStemdoerById = (stemdoerId: string): Stemdoer => {
    return this.find(stemdoerRate => stemdoerRate.stemdoer.id === stemdoerId)?.stemdoer;
  };

  findRateByStemdoerId = (stemdoerId: string): number => {
    return this.find(stemdoerRate => stemdoerRate.stemdoer.id === stemdoerId)?.rate;
  };

  findByStemdoerId = (stemdoerId: string): StemdoerRate => {
    return this.find(stemdoerRate => stemdoerRate.stemdoer.id === stemdoerId);
  };

  setRates = (clientRates: Rates) => {
    this.forEach(stemdoerRate => {
      stemdoerRate.setRate(clientRates);
    });
  };
}
