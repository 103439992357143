var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "px-6 pt-3 pb-4 mx-0",
      style: {
        backgroundColor: "var(--v-background-darken1)",
        borderRadius: "4px",
        minWidth: "100%",
      },
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                {
                  class: { "mb-2": _vm.statusInfoText !== undefined },
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-4", attrs: { cols: "auto" } },
                    [
                      _c("span", {
                        staticClass: "text-h4 text--text text--base",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("teamRequestDetail.statusHeader.title")
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c("SLabel", {
                        attrs: {
                          text: _vm.statusLabelText,
                          labelStyle: _vm.labelStyle,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _c("span", {
                  staticClass: "text-body-1 text--text text--base",
                  domProps: { textContent: _vm._s(_vm.statusInfoText) },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }