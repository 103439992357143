import { computed, defineComponent, PropType, ref, watch } from 'vue';

export enum LabelStyles {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Quaternary = 'quaternary',
  Quinary = 'quinary',
}

export default defineComponent({
  props: {
    text: {
      type: String,
    },
    labelStyle: {
      type: String as () => LabelStyles,
      validator: (value: any): value is LabelStyles => Object.values(LabelStyles).includes(value),
      default: LabelStyles.Secondary,
    },
  },
  setup(props) {
    const backgroundColor = computed(() => {
      switch (props.labelStyle) {
        case LabelStyles.Primary:
          return 'var(--v-primary-lighten1)';
        case LabelStyles.Secondary:
          return 'var(--v-secondary-lighten1)';
        case LabelStyles.Tertiary:
          return 'var(--v-tertiary-lighten1)';
        case LabelStyles.Quaternary:
          return 'var(--v-quaternary-lighten1)';
        case LabelStyles.Quinary:
          return 'var(--v-quinary-lighten1)';
        default:
          throw new Error('Invalid label style');
      }
    });
    const borderColor = computed(() => {
      switch (props.labelStyle) {
        case LabelStyles.Primary:
          return 'var(--v-primary-base)';
        case LabelStyles.Secondary:
          return 'var(--v-secondary-base)';
        case LabelStyles.Tertiary:
          return 'var(--v-tertiary-base)';
        case LabelStyles.Quaternary:
          return 'var(--v-quaternary-base)';
        case LabelStyles.Quinary:
          return 'var(--v-quinary-base)';
        default:
          throw new Error('Invalid label style');
      }
    });
    return { backgroundColor, borderColor };
  },
});
