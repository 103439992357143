var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.authenticated
    ? _c(
        "div",
        [
          _c(
            "b-navbar",
            {
              staticClass: "bg-primary",
              attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
            },
            [
              !_vm.authenticated
                ? _c(
                    "b-navbar-brand",
                    { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
                    [
                      _c("span", { staticClass: "logo-img" }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "navbar-title",
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.title")),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated &&
              _vm.hasAnyAuthority([
                _vm.Authority.ADMIN,
                _vm.Authority.CLIENT,
                _vm.Authority.RRHH,
              ])
                ? _c(
                    "b-navbar-brand",
                    {
                      staticClass: "logo",
                      attrs: {
                        "b-link": "",
                        to:
                          _vm.hasAnyAuthority([_vm.Authority.RRHH]) == true
                            ? "/rrhhHome"
                            : _vm.hasAnyAuthority([_vm.Authority.CLIENT]) ==
                              true
                            ? "/"
                            : "/teams",
                      },
                    },
                    [
                      _c("span", { staticClass: "logo-img" }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "navbar-title",
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.title")),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-navbar-toggle",
                {
                  staticClass: "jh-navbar-toggler d-lg-none",
                  attrs: {
                    right: "",
                    href: "javascript:void(0);",
                    "data-toggle": "collapse",
                    target: "header-tabs",
                    "aria-expanded": "false",
                    "aria-label": "Toggle navigation",
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                { attrs: { "is-nav": "", id: "header-tabs" } },
                [
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _c("span", { staticStyle: { "padding-right": "1em" } }),
                      _vm._v(" "),
                      !_vm.authenticated
                        ? _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                            _c("span", [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("global.menu.home")
                                  ),
                                },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.authenticated &&
                      !_vm.hasAnyAuthority([_vm.Authority.STEMDOER])
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: { "data-cy": "home", to: "/", exact: "" },
                            },
                            [
                              _c(
                                "span",
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "home" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.home")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAnyAuthority([_vm.Authority.CLIENT]) &&
                      _vm.authenticated
                        ? _c("b-nav-item-dropdown", {
                            attrs: { "data-cy": "requestMenu", exact: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "navbar-dropdown-menu" },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: "fa-solid fa-people-group",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("global.menu.request")
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1927440402
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAnyAuthority([_vm.Authority.ADMIN]) &&
                      _vm.authenticated
                        ? _c(
                            "b-nav-item-dropdown",
                            {
                              staticClass: "pointer",
                              class: {
                                "router-link-active": _vm.subIsActive("/admin"),
                              },
                              attrs: {
                                right: "",
                                id: "entity-menu",
                                "active-class": "active",
                                "data-cy": "entity",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "navbar-dropdown-menu",
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "no-bold",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "global.menu.entities.main"
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                985449070
                              ),
                            },
                            [_vm._v(" "), _c("entities-menu")],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAnyAuthority([_vm.Authority.ADMIN]) &&
                      _vm.authenticated
                        ? _c(
                            "b-nav-item-dropdown",
                            {
                              staticClass: "pointer",
                              class: {
                                "router-link-active": _vm.subIsActive("/admin"),
                              },
                              attrs: {
                                right: "",
                                id: "admin-menu",
                                "active-class": "active",
                                "data-cy": "adminMenu",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "navbar-dropdown-menu",
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "users-cog" },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "no-bold",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "global.menu.admin.main"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                59346824
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    to: "/admin/user-management",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "users" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "global.menu.admin.userManagement"
                                        )
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    to: "/admin/metrics",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "tachometer-alt" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.admin.metrics")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    to: "/admin/logs",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "tasks" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.admin.logs")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.languages && Object.keys(_vm.languages).length > 1
                        ? _c(
                            "b-nav-item-dropdown",
                            {
                              attrs: {
                                id: "languagesnavBarDropdown",
                                right: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "flag" },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "no-bold",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("global.menu.language")
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                612111166
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    id: "langKey",
                                    name: "langKey",
                                    model: "settingsAccount.langKey",
                                    "data-cy": "langKey",
                                  },
                                },
                                _vm._l(_vm.languages, function (value, key) {
                                  return _c(
                                    "b-dropdown-item",
                                    {
                                      key: `lang-${key}`,
                                      attrs: {
                                        "active-class": "active",
                                        value: key,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.save(key)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(value.name) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-nav-item-dropdown",
                        {
                          staticClass: "pointer",
                          class: {
                            "router-link-active": _vm.subIsActive("/account"),
                          },
                          attrs: {
                            right: "",
                            href: "javascript:void(0);",
                            id: "account-menu",
                            "active-class": "active",
                            "data-cy": "accountMenu",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "navbar-dropdown-menu" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "user" },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "no-bold",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("global.menu.account.main")
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2607027827
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm.authenticated &&
                          !_vm.hasAnyAuthority([_vm.Authority.STEMDOER])
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-cy": "passwordItem",
                                    to: "/account/password",
                                    tag: "b-dropdown-item",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "lock" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.account.password")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.authenticated
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-cy": "logout",
                                    id: "logout",
                                    "active-class": "active",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.logout()
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "sign-out-alt" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.account.logout")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.authenticated
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-cy": "login",
                                    id: "login",
                                    "active-class": "active",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openLogin()
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "sign-in-alt" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.account.login")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.authenticated
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-cy": "register",
                                    to: "/register",
                                    tag: "b-dropdown-item",
                                    id: "register",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "user-plus" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.account.register")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : (this.url == "not-found" ||
        this.url == undefined ||
        this.url == "account/activate" ||
        this.url == "order" ||
        this.url != "" ||
        this.url != "cookieInfo") &&
      this.singleUrlWord == "login" &&
      this.singleUrlWord == "register" &&
      this.isHome != true
    ? _c(
        "div",
        [
          _c(
            "b-navbar",
            {
              staticClass: "bg-primary",
              attrs: { toggleable: "md", type: "dark" },
            },
            [
              !_vm.authenticated
                ? _c(
                    "b-navbar-brand",
                    {
                      staticClass: "logo",
                      attrs: { "b-link": "", to: _vm.getToLink() },
                      on: {
                        click: function ($event) {
                          return _vm.handleClick()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "logo-img" }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "navbar-title",
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.title")),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-navbar-toggle",
                {
                  staticClass: "jh-navbar-toggler d-lg-none",
                  attrs: {
                    right: "",
                    href: "javascript:void(0);",
                    "data-toggle": "collapse",
                    target: "header-tabs",
                    "aria-expanded": "false",
                    "aria-label": "Toggle navigation",
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                { attrs: { "is-nav": "", id: "header-tabs" } },
                [
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      !_vm.authenticated
                        ? _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                            _c("span", [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("global.menu.home")
                                  ),
                                },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.authenticated
                        ? _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                            _c(
                              "span",
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "home" },
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.home")
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAnyAuthority([_vm.Authority.ADMIN]) &&
                      _vm.authenticated
                        ? _c(
                            "b-nav-item-dropdown",
                            {
                              staticClass: "pointer",
                              class: {
                                "router-link-active": _vm.subIsActive("/admin"),
                              },
                              attrs: {
                                right: "",
                                id: "entity-menu",
                                "active-class": "active",
                                "data-cy": "entity",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "navbar-dropdown-menu",
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "no-bold",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "global.menu.entities.main"
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                985449070
                              ),
                            },
                            [_vm._v(" "), _c("entities-menu")],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAnyAuthority([_vm.Authority.ADMIN]) &&
                      _vm.authenticated
                        ? _c(
                            "b-nav-item-dropdown",
                            {
                              staticClass: "pointer",
                              class: {
                                "router-link-active": _vm.subIsActive("/admin"),
                              },
                              attrs: {
                                right: "",
                                id: "admin-menu",
                                "active-class": "active",
                                d: "adminMenu",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "navbar-dropdown-menu",
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "users-cog" },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "no-bold",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "global.menu.admin.main"
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                59346824
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    to: "/admin/user-management",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "users" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "global.menu.admin.userManagement"
                                        )
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    to: "/admin/metrics",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "tachometer-alt" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.admin.metrics")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    to: "/admin/logs",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "tasks" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.admin.logs")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.languages && Object.keys(_vm.languages).length > 1
                        ? _c(
                            "b-nav-item-dropdown",
                            {
                              attrs: {
                                id: "languagesnavBarDropdown",
                                right: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "flag" },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "no-bold",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("global.menu.language")
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                612111166
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: {
                                    id: "langKey",
                                    name: "langKey",
                                    model: "settingsAccount.langKey",
                                    "data-cy": "langKey",
                                  },
                                },
                                _vm._l(_vm.languages, function (value, key) {
                                  return _c(
                                    "b-dropdown-item",
                                    {
                                      key: `lang-${key}`,
                                      attrs: {
                                        "active-class": "active",
                                        value: key,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.save(key)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(value.name) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-nav-item-dropdown",
                        {
                          staticClass: "pointer",
                          class: {
                            "router-link-active": _vm.subIsActive("/account"),
                          },
                          attrs: {
                            right: "",
                            href: "javascript:void(0);",
                            id: "account-menu",
                            "active-class": "active",
                            "data-cy": "accountMenu",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "navbar-dropdown-menu" },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "user" },
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "no-bold",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("global.menu.account.main")
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _vm.authenticated
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-cy": "passwordItem",
                                    to: "/account/password",
                                    tag: "b-dropdown-item",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "lock" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.account.password")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.authenticated
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-cy": "logout",
                                    id: "logout",
                                    "active-class": "active",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.logout()
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "sign-out-alt" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.account.logout")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.authenticated
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-cy": "login",
                                    id: "login",
                                    "active-class": "active",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openLogin()
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "sign-in-alt" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.account.login")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.authenticated
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-cy": "register",
                                    to: "/register",
                                    tag: "b-dropdown-item",
                                    id: "register",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "user-plus" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.menu.account.register")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }