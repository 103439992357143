import router from '@/router';

export default {
  setup() {
    const goToLoginPage = () => {
      router.push('/login');
    };

    const goToRegisterPage = () => {
      router.push('/register');
    };

    const goToCandidatesTypeForm = () => {
      window.location.href = 'https://wqe5865x2sl.typeform.com/to/Xsxf3xDD?typeform-source=platform.stemdo.io';
    };

    return { goToLoginPage, goToRegisterPage, goToCandidatesTypeForm };
  },
};
