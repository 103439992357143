var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "p-0" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("SButton", {
                attrs: {
                  sText: _vm.$t(
                    "teamRequestDetail.buttonGroup.client.savedToPendingTeamRequest.primaryButtonText"
                  ),
                  btnStyle: "primary",
                  action: _vm.openSavedToPendingTeamRequestModal,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }