var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "input-number-component h-100" },
    [
      _c("v-img", {
        staticClass: "icon ml-1",
        attrs: { src: "/content/svgs/minus.svg" },
        on: {
          mousedown: _vm.startDecrement,
          mouseup: _vm.stopChange,
          mouseleave: _vm.stopChange,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-display text-body-1-medium" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputVal,
              expression: "inputVal",
            },
          ],
          staticClass: "input pt-0",
          attrs: {
            type: "number",
            min: "0",
            max: "999.99",
            step: "0.01",
            maxlength: "6",
          },
          domProps: { value: _vm.inputVal },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.inputVal = $event.target.value
              },
              _vm.validateRate,
            ],
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "pt-0" }),
      ]),
      _vm._v(" "),
      _c("v-img", {
        staticClass: "icon mr-1",
        attrs: { src: "/content/svgs/plus.svg" },
        on: {
          mousedown: _vm.startIncrement,
          mouseup: _vm.stopChange,
          mouseleave: _vm.stopChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }