var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "m-0 px-8 pt-6",
      attrs: { id: "candidates-wrapper", fluid: "" },
    },
    [
      _c(
        "v-col",
        {
          staticClass: "pa-0 ma-0 d-flex align-center",
          attrs: { col: "2", fluid: "" },
        },
        [
          _c("h3", {
            staticClass: "text-h3 title-page",
            domProps: { textContent: _vm._s(_vm.$t("candidate.title")) },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("v-progress-circular", {
            attrs: { width: 2, color: "grey", indeterminate: "" },
          })
        : [
            !_vm.isCandidateListEmpty
              ? [
                  _c(
                    "v-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass:
                                "d-flex candidates-tip justify-space-between align-items-center mx-0 mb-0 pl-0 pt-0",
                              attrs: { fluid: "" },
                            },
                            [
                              _c("span", {
                                staticClass: "text-subtitle-3 mt-4",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("candidate.tip")),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("CandidatesFilter", {
                    attrs: { search: _vm.search },
                    on: {
                      "update:search": function ($event) {
                        _vm.search = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("CandidatesList", {
                    attrs: { candidates: _vm.filteredCandidates },
                  }),
                ]
              : [_c("NoContent")],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }