import { ClientRate, IClientRate } from '@/shared/model/client-rate.model';

export interface IRate {
  seniority?: string;
  value?: number;
  clientRate?: IClientRate;
}

export class Rate implements IRate {
  seniority?: string;
  value?: number;
  clientRate?: ClientRate | null;

  constructor(data: IRate) {
    this.seniority = data?.seniority;
    this.value = data?.value;
    this.clientRate = data?.clientRate;
  }

  static empty = () => {
    return new Rate({
      seniority: undefined,
      value: undefined,
      clientRate: undefined
    });
  };
}

export class Rates extends Array<Rate> {
  constructor(data: Rate[]) {
    super(...data);
  }

  static empty = () => {
    return new Rates([]);
  };

  static from = (data: IRate[]): Rates => {
    return new Rates(data.map(rate => new Rate(rate)));
  };

  static fromI = (data: IRate[]): Rates => {
    return new Rates(data.map(rate => new Rate(rate)));
  };

  getBySeniority = (seniority: string): Rate | undefined => {
    return this.find(rate => {
      const rateSeniority = rate.seniority === 'Mid-Senior' ? 'MID_SENIOR' : rate.seniority;
      return rateSeniority.toLowerCase() === seniority.toLowerCase();
    });
  };
}
