import { defineComponent } from 'vue';
import useRouter from 'vue-router';

export default defineComponent({
  props: {
    onClick: {
      type: Function,
      required: false
    }
  },
  setup(props) {
    const router = new useRouter();

    const goBack = async () => {
      if (props.onClick) {
        props.onClick();
        return;
      }

      try {
        await router.back();
      } catch (error) {
        console.error('Error al navegar hacia atrás:', error);
      }
    };

    return {
      goBack
    };
  }
});
