var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "mr-4" }, [
            _c("div", { staticClass: "box" }, [
              _c("span", {
                staticClass: "text-body-2-medium text--text text--lighten-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("teamRequestDetail.overview.dailyCost")
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-h3 text--text text--base" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.hourlyRate && _vm.hourlyRate > 0
                        ? _vm.$n(_vm.hourlyRate * 8, "decimal") + "€"
                        : "---"
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("div", { staticClass: "box" }, [
              _c("span", {
                staticClass: "text-body-2-medium text--text text--lighten-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("teamRequestDetail.overview.monthlyCost")
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-h3 text--text text--base" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.hourlyRate && _vm.hourlyRate > 0
                        ? _vm.$n(_vm.hourlyRate * 8 * 20, "decimal") + "€"
                        : "---"
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c("div", { staticClass: "box" }, [
              _c("span", {
                staticClass:
                  "text-body-2-medium text--text text--lighten-1 mb-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("teamRequestDetail.overview.teamRequestName")
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-body-1 text--text text--base" }, [
                _vm._v(_vm._s(_vm.teamRequest.name)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "mr-4" }, [
            _c("div", { staticClass: "box" }, [
              _c("span", {
                staticClass:
                  "text-body-2-medium text--text text--lighten-1 mb-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("teamRequestDetail.overview.collaborationStart")
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text-body-1 text--text text--base",
                domProps: {
                  innerHTML: _vm._s(
                    !_vm.teamRequest?.initDate
                      ? _vm.$t("teamRequestDetail.overview.indefiniteDate")
                      : _vm.$d(new Date(_vm.teamRequest.initDate), "twoDigit")
                  ),
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("div", { staticClass: "box" }, [
              _c("span", {
                staticClass:
                  "text-body-2-medium text--text text--lighten-1 mb-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("teamRequestDetail.overview.collaborationEnd")
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text-body-1 text--text text--base",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.teamRequest?.endDate === undefined ||
                      _vm.teamRequest?.endDate === null
                      ? _vm.$t("teamRequestDetail.overview.indefiniteDate")
                      : _vm.$d(Date.parse(_vm.teamRequest?.endDate), "twoDigit")
                  ),
                },
              }),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.teamRequest.jobDescription && _vm.teamRequest.jobDescription !== ""
        ? _c(
            "v-row",
            { staticClass: "mt-4", attrs: { "no-gutters": "" } },
            [
              _c("v-col", [
                _c("div", { staticClass: "box" }, [
                  _c("span", {
                    staticClass:
                      "text-body-2-medium text--text text--lighten-1 mb-1",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("teamRequestDetail.overview.observations")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "text-body-1 text--text text--base" },
                    [_vm._v(_vm._s(_vm.teamRequest.jobDescription))]
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }