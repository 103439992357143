var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "label",
      style: {
        backgroundColor: _vm.backgroundColor,
        borderColor: _vm.borderColor,
      },
    },
    [
      _c("span", {
        staticClass: "text-body-3 text--text text--base",
        domProps: { textContent: _vm._s(_vm.text) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }