import { computed, defineComponent, onMounted } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import SInputNumber from '@/components/stemdo-components/s-input-number/SInputNumber.vue';
import TagInterest from './tag-interest/TagInterest.vue';
import { Stemdoer } from '@/shared/model/stemdoer.model';

export type CardOptions = {
  isRateVisible?: boolean;
  isRateEditable?: boolean;
  isDeletable?: boolean;
  isDisabled?: boolean;
  isClickable?: boolean;
  displayInterestTag?: boolean;
  onDelete?: (stemdoerId: string) => void;
  onRateChange?: (stemdoerId: string, newRate: number) => void;
  onClick?: (stemdoerId: string) => void;
};

export default defineComponent({
  props: {
    stemdoer: {
      type: Object as () => Stemdoer,
      required: true
    },
    rate: {
      type: Number
    },
    cardOptions: {
      type: Object as () => CardOptions,
      required: false
    }
  },
  components: {
    SAvatarStemdoer,
    SInputNumber,
    TagInterest
  },
  setup(props: { stemdoer: Stemdoer; rate: number; cardOptions?: CardOptions }) {
    const firstFourTechnologyScores = computed(() => props.stemdoer.technologyScores.slice(0, 4));

    onMounted(() => {
      if (props.cardOptions.isRateEditable && !props.cardOptions.onRateChange) {
        throw new Error('onRateChange is required when rate is editable');
      }
      if (props.cardOptions.isDeletable && !props.cardOptions.onDelete) {
        throw new Error('onDelete is required when card is deletable');
      }
      if (props.cardOptions.isClickable && !props.cardOptions.onClick) {
        throw new Error('onClick is required when card is clickable');
      }
    });

    const onRateChange = (newRate: number) => {
      props.cardOptions.onRateChange(props.stemdoer.id, newRate);
    };

    const onDeleteClick = (stemdoerId: string) => {
      props.cardOptions.onDelete(stemdoerId);
    };

    const onClick = (stemdoerId: string) => {
      props.cardOptions.onClick(stemdoerId);
    };

    return {
      firstFourTechnologyScores,
      onRateChange,
      onDeleteClick,
      onClick
    };
  }
});
