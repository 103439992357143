import SButtonBack from '@/components/stemdo-components/s-button-back/SButtonBack.vue';

export default {
  props: {
    title: {
      type: String
    },
    onBackButtonClick: {
      type: Function,
      required: false
    }
  },
  components: {
    SButtonBack
  },
  setup() {
    return {};
  }
};
