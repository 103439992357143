var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "v-dialog-component",
        "max-width": _vm.maxWidth,
        persistent: _vm.persistent,
      },
      on: { "click:outside": _vm.clickOutside, keydown: _vm.keyDown },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "modal-centered-content" } },
        [
          _c(
            "v-card-title",
            { staticClass: "px-8 pt-4 pb-2" },
            [
              _c(
                "v-container",
                { staticClass: "p-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "detail-link" },
                          [
                            _c("span", {
                              staticClass: "text-h4 text--text text--base",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "teamRequestDetail.modal.client.saveTeamRequest.title"
                                  )
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _vm._t("title-append"),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto", "align-self": "center" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "close-button mr-n3",
                              on: { click: _vm.close },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  width: 24,
                                  src: "/content/svgs/close-modal.svg",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            {
              class: "px-8 py-" + _vm.newProps.bodyPaddingY,
              staticStyle: { color: "#222226" },
            },
            [
              [
                _c("div", {
                  staticClass: "text-body-1 text--text",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "teamRequestDetail.modal.client.saveTeamRequest.info1"
                      )
                    ),
                  },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "mt-6 text-body-1 text--text",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "teamRequestDetail.modal.client.saveTeamRequest.info2"
                      )
                    ),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mt-8" }, [
                  _c("span", {
                    staticClass: "modal-span text-subtitle-5",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "teamRequestDetail.modal.client.saveTeamRequest.inputLabel"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.teamRequestName,
                        expression: "teamRequestName",
                      },
                    ],
                    ref: "teamRequestNameRef",
                    staticClass: "input-form-stemdo mt-2",
                    attrs: {
                      id: "teamRequestName",
                      type: "text",
                      placeholder: _vm
                        .$t("label.teams.selected.namePlaceholder")
                        .toString(),
                    },
                    domProps: { value: _vm.teamRequestName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.teamRequestName = $event.target.value
                      },
                    },
                  }),
                ]),
              ],
              _vm._v(" "),
              [
                _c(
                  "v-container",
                  { staticClass: "p-0 mt-8" },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pr-2", attrs: { cols: "6" } },
                          [
                            _c("SButton", {
                              attrs: {
                                action: _vm.gotoExplore,
                                btnStyle: "secondary",
                                sText: _vm.$t(
                                  "teamRequestDetail.modal.client.saveTeamRequest.secondaryButtonText"
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "pl-2", attrs: { cols: "6" } },
                          [
                            _c("SButton", {
                              attrs: {
                                action: _vm.saveTeamRequestWithStemdoersInStore,
                                btnStyle: "primary",
                                sText: _vm.$t(
                                  "teamRequestDetail.modal.client.saveTeamRequest.primaryButtonText"
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
          _vm._v(" "),
          _c("v-card-actions", { staticClass: "px-8 py-4" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }