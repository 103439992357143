var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "p-0" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c(_vm.currentComponent, {
                tag: "component",
                attrs: {
                  teamRequest: _vm.teamRequest,
                  refreshTeamRequest: _vm.refreshTeamRequest,
                  onSuccess: _vm.onSuccess,
                  onError: _vm.onError,
                  onOpenModal: _vm.openModal,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("TRValidatedModal", {
        attrs: { isOpen: _vm.isTRValidatedModalOpen, onClose: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("TRCancelledModal", {
        attrs: { isOpen: _vm.isTRCancelledModalOpen, onClose: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("SendProposalModal", {
        attrs: { isOpen: _vm.isSendProposalModalOpen, onClose: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("NewProposalModal", {
        attrs: { isOpen: _vm.isNewProposalModalOpen, onClose: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("SavedToPendingTRModal", {
        attrs: {
          isOpen: _vm.isSavedToPendingTRModalOpen,
          onClose: _vm.closeModal,
          teamRequest: _vm.teamRequest,
          refreshTeamRequest: _vm.refreshTeamRequest,
          onSuccess: _vm.onSuccess,
          onError: _vm.onError,
          disabled: _vm.isDisabled,
        },
      }),
      _vm._v(" "),
      _c("SavedTROnlyAvailableStemdoersModal", {
        attrs: {
          isOpen: _vm.isSavedToPendingTROnlyAvailablesModalOpen,
          onClose: _vm.closeModal,
          teamRequest: _vm.teamRequest,
          refreshTeamRequest: _vm.refreshTeamRequest,
          onSuccess: _vm.onSuccess,
          onError: _vm.onError,
        },
      }),
      _vm._v(" "),
      _c("AcceptTRModal", {
        attrs: {
          isOpen: _vm.isAcceptTRModalOpen,
          onClose: _vm.closeModal,
          teamRequest: _vm.teamRequest,
          refreshTeamRequest: _vm.refreshTeamRequest,
          onSuccess: _vm.onSuccess,
          onError: _vm.onError,
          disabled: _vm.isDisabled,
        },
      }),
      _vm._v(" "),
      _c("CheckCartSaveTrModal", {
        attrs: {
          title: _vm.$t(
            "teamRequestDetail.modal.client.acceptTeamRequest.title"
          ),
          isOpen: _vm.isCheckCartSaveTrModalOpen,
          onClose: _vm.closeModal,
          teamRequestId: _vm.teamRequest.id,
        },
      }),
      _vm._v(" "),
      _c("AcceptTROnlyAvailableStemdoersModal", {
        attrs: {
          isOpen: _vm.isAcceptTeamRequestOnlyAvailableModalOpen,
          onClose: _vm.closeModal,
          teamRequest: _vm.teamRequest,
          refreshTeamRequest: _vm.refreshTeamRequest,
          onSuccess: _vm.onSuccess,
          onError: _vm.onError,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }