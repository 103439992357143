var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.Explore, {
    attrs: {
      teamRequestId: _setup.teamRequestId,
      loadTrToCart: _setup.loadTrToCart,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }