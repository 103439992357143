import { Component, Emit, Inject, Vue } from 'vue-property-decorator';

@Component({
  name: 'ButtonComponent',
  props: {
    conf: {
      type: Object,
      required: true,
    },
  },
})
export default class Stemdocomponents extends Vue {}
