import { defineComponent, inject, PropType, ref, watch } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { TeamRequest } from '@/shared/model/team-request.model';
import { ButtonGroupModals } from '../../../buttonsGroup';
import SModalTeamRequestConfirmation from '@/components/stemdo-components/s-modal-team-request-confirmation/SModalTeamRequestConfirmation.vue';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { useStore } from '@/plugins/vuex';
import axios from 'axios';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    onOpenModal: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton,
    SModalCentered,
    SModalTeamRequestConfirmation
  },
  setup(props) {
    const store = useStore();
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const isTeamRequestConfirmationModalOpen = ref(false);

    const openTeamRequestConfirmationModal = () => {
      isTeamRequestConfirmationModalOpen.value = true;
    };

    const closeTeamRequestConfirmationModal = () => {
      isTeamRequestConfirmationModalOpen.value = false;
    };

    const createTeamRequest = (teamRequest: TeamRequest) => {
      teamRequest.status = RequestStatus.VALIDATED;

      teamRequestService
        .create(teamRequest)
        .then(res => {
          const teamRequest = new TeamRequest(res);
          sendEmail(teamRequest);
          props.onOpenModal(ButtonGroupModals.MANAGER_CHECKOUT_CONFIRMATION, { id: res.id });
        })
        .catch(error => {
          console.error(error);
        });
    };

    const sendEmail = (teamRequest: TeamRequest) => {
      const rates: { [key: string]: number } = {};
      let stemdoersId = '';
      const stemdoerNames: string[] = [];
      teamRequest.stemdoerRates.forEach(stemdoerRate => {
        rates[stemdoerRate.stemdoer.id] = stemdoerRate.rate;
        stemdoersId += `${stemdoerRate.stemdoer.id},`;
        stemdoerNames.push(
          `${stemdoerRate.stemdoer.name.charAt(0).toLocaleUpperCase()}${stemdoerRate.stemdoer.name.slice(1)} ${stemdoerRate.stemdoer.surname
            .charAt(0)
            .toLocaleUpperCase()}${stemdoerRate.stemdoer.surname.slice(1)}`
        );
      });
      stemdoersId = stemdoersId.slice(0, -1);
      const myData = {
        paramId: teamRequest.id,
        isTeam: true,
        ratesList: rates,
        stemdoerShowBadge: {},
        initDate: teamRequest.initDate,
        endDate: teamRequest.endDate,
        locale: store.getters['currentLanguage'],
        idClient: teamRequest.client.id,
        nameClient: teamRequest.client.name,
        emailClient: teamRequest.client.email,
        nameStemdoers: stemdoerNames
      };

      const myJSON = JSON.stringify(myData);
      axios
        .get(`api/import/pdf?email=${teamRequest.client.email}`, {
          params: {
            listIdMongo: stemdoersId,
            dataNecessary: myJSON
          }
        })
        .catch((err: Error) => {
          console.error(err);
        });
    };

    const onClickTeamRequestConfirmationModal = (teamRequestName: string, initDate: string, endDate: string, jobDescription: string) => {
      const mutableTeamRequest = { ...props.teamRequest };
      mutableTeamRequest.name = teamRequestName;
      mutableTeamRequest.initDate = new Date(initDate);
      mutableTeamRequest.endDate = endDate ? new Date(endDate) : null;
      mutableTeamRequest.jobDescription = jobDescription;
      createTeamRequest(mutableTeamRequest);
    };

    return {
      isTeamRequestConfirmationModalOpen,
      openTeamRequestConfirmationModal,
      closeTeamRequestConfirmationModal,
      onClickTeamRequestConfirmationModal
    };
  }
});
