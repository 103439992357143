import { onMounted, ref } from 'vue';
import { useStore } from '@/plugins/vuex';

export default {
  props: {
    teamRequests: {
      type: Array,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const displayTag = ref(false);
    const loggedClientId = ref(store.getters['client'].id);

    onMounted(() => {
      const filterdByClientTeamRequets = props.teamRequests.filter(tr => tr.clientId !== loggedClientId.value);
      displayTag.value = filterdByClientTeamRequets.length > 0;
    });

    return {
      displayTag
    };
  }
};
