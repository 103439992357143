var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("SModalCentered", {
    attrs: {
      title: _vm.$t("sModalStemdoerNotAvailable.title"),
      isOpen: _vm.isOpen,
      closeModal: _vm.handleClose,
      customProps: { bodyPaddingY: 0, fullWidthFooter: true },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", {
              staticClass: "text-body-1 text--text mt-2",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("sModalStemdoerNotAvailable.message")
                ),
              },
            }),
            _vm._v(" "),
            _c("div", [
              _c(
                "ul",
                { staticStyle: { "list-style-type": "none", padding: "0" } },
                _vm._l(_vm.stemdoers, function (stemdoer, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "p-3 mt-4 w-100 h-100 container",
                    },
                    [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c(
                          "div",
                          { staticClass: "mr-4" },
                          [
                            _c("SAvatarStemdoer", {
                              staticStyle: {
                                height: "55px !important",
                                width: "55px !important",
                              },
                              attrs: {
                                stemdoId: stemdoer.stemdoId,
                                size: "55px",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pr-2" }, [
                          _c("div", [
                            _c("span", { staticClass: "text-body-1" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    stemdoer.name +
                                      " " +
                                      stemdoer.surname.charAt(0) +
                                      "."
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-2" }, [
                            _c("span", { staticClass: "text-body-1" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(stemdoer.position) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("span", { staticClass: "text-body-1-bold" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "sModalStemdoerNotAvailable.stemdoer.availableAt"
                                )
                              ) +
                              "\n              " +
                              _vm._s(
                                stemdoer.availability.availableAt
                                  ? _vm.$d(
                                      Date.parse(
                                        stemdoer.availability.availableAt
                                      ),
                                      "twoDigit"
                                    )
                                  : _vm.$t(
                                      "sModalStemdoerNotAvailable.stemdoer.indefinite"
                                    )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "text-body-1 text--text",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("sModalStemdoerNotAvailable.message2")
                ),
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "v-container",
              { staticClass: "p-0" },
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "p-0", attrs: { cols: "12" } },
                      [
                        _c("SButton", {
                          attrs: {
                            action: _vm.handleClose,
                            btnStyle: "primary",
                            sText: _vm.$t(
                              "sModalStemdoerNotAvailable.primaryButtonText"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }