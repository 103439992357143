import { computed, onMounted, ref, watch } from 'vue';
import { useI18N } from '@/plugins/i18n';
import { TeamRequest } from '@/shared/model/team-request.model';

type ModalProps = {
  title: string;
  primaryButtonText: string;
};

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    action: {
      type: Function as (teamRequestName: string, initDate: string, endDate: string, jobDescription: string) => void,
      required: true
    },
    customProps: {
      type: Object as () => ModalProps,
      default: () => ({
        title: 'sModalTeamRequestConfirmation.title',
        primaryButtonText: 'sModalTeamRequestConfirmation.label.confirmBtn',
        initDateRequired: true
      })
    }
  },
  setup(props) {
    const i18n = useI18N();
    const isInitDateCalendarOpen = ref(false);
    const isEndDateOpenCalendar = ref(false);
    const isEndDateInputHidden = ref(true);
    const teamRequestNameRef = ref(undefined);
    const initDateRef = ref(undefined);
    const endDateRef = ref(undefined);
    const teamRequestDescriptionRef = ref(undefined);
    const isButtonDisabled = ref(true);
    const formattedInitDate = computed(() => {
      if (!initDateRef) return null;
      return formatDate(initDateRef.value);
    });
    const formattedEndDate = computed(() => {
      if (!endDateRef) return null;
      return formatDate(endDateRef.value);
    });

    watch(
      () => props.teamRequest,
      () => {
        teamRequestNameRef.value = props.teamRequest?.name;
        teamRequestDescriptionRef.value = props.teamRequest?.jobDescription;
        initDateRef.value = props.teamRequest?.initDate;
        endDateRef.value = props.teamRequest?.endDate;
        if (endDateRef.value) isEndDateInputHidden.value = false;
      },
      { immediate: true, deep: true }
    );
    const isFormValid = () => {
      const startDate = initDateRef.value ? new Date(initDateRef.value) : null;
      const endDate = endDateRef.value ? new Date(endDateRef.value) : null;
      const isDateValid = !endDate || (startDate && endDate > startDate);

      if (props.customProps.initDateRequired) {
        isButtonDisabled.value = !(teamRequestNameRef.value && initDateRef.value && isDateValid);
      } else {
        isButtonDisabled.value = !(teamRequestNameRef.value && isDateValid);
      }
    };

    watch(
      () => props.customProps.initDateRequired,
      () => {
        isFormValid();
      },
      { immediate: true, deep: true }
    );

    watch([teamRequestNameRef, initDateRef, endDateRef], isFormValid, { immediate: true });

    const closeInitDateCalender = () => {
      isInitDateCalendarOpen.value = false;
    };

    const closeEndDateCalender = () => {
      isEndDateOpenCalendar.value = false;
    };

    const formatDate = (date: string) => {
      if (!date) return null;
      return i18n.d(new Date(date), 'long').replace(/ de /g, ' ');
    };

    const toggleEndDateInput = () => {
      isEndDateInputHidden.value = !isEndDateInputHidden.value;

      if (!isEndDateInputHidden.value) {
        endDateRef.value = null;
        closeEndDateCalender();
      }
    };

    const adjustDate = (dateText: string, days: number) => {
      if (!dateText) return null;
      const date = new Date(dateText);
      date.setDate(date.getDate() + days);
      return date.toISOString();
    };

    const onClick = () => {
      if (isButtonDisabled.value) {
        console.error('Form is not valid');
        return;
      }
      props.onClose();
      props.action(teamRequestNameRef.value, initDateRef.value, endDateRef.value, teamRequestDescriptionRef.value);
    };

    return {
      teamRequestNameRef,
      initDateRef,
      endDateRef,
      teamRequestDescriptionRef,
      formattedInitDate,
      formattedEndDate,
      isInitDateCalendarOpen,
      isEndDateOpenCalendar,
      isEndDateInputHidden,
      isButtonDisabled,
      toggleEndDateInput,
      closeInitDateCalender,
      closeEndDateCalender,
      onClick,
      adjustDate
    };
  }
};
