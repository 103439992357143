import { Vertical } from './vertical.model';

export interface ITechnology {
  id?: number;
  name?: string;
}

export class Technology implements ITechnology {
  id?: number;
  name?: string;

  constructor(data: ITechnology) {
    this.id = data?.id;
    this.name = data.name;
  }

  static empty(): Technology {
    return new Technology({});
  }

  static from(data: ITechnology): Technology {
    return new Technology(data);
  }
}

export class Technologies extends Array<Technology> {
  constructor(data: Technology[]) {
    super(...data);
  }

  static empty(): Technologies {
    return new Technologies([]);
  }

  static from(data: Technology[]): Technologies {
    if (!data) return null;
    return new Technologies(data.map(technology => new Technology(technology)));
  }

  findById = (id: number): Technology => {
    return this.find(technology => technology.id === id);
  };
}
