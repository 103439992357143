import { defineComponent, onMounted, ref } from 'vue';

export enum BtnStyles {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Quaternary = 'quaternary'
}

export default defineComponent({
  props: {
    btnStyle: {
      type: String as () => BtnStyles,
      validator: (value: any): value is BtnStyles => Object.values(BtnStyles).includes(value),
      default: BtnStyles.Primary
    },
    sText: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: false
    },
    iconSrc: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isDepressed = ref(false);
    const isOutlined = ref(false);

    onMounted(() => {
      initBtn();
    });

    const initBtn = () => {
      if (props.btnStyle === BtnStyles.Primary || props.btnStyle === BtnStyles.Quaternary) {
        isDepressed.value = true;
      } else if (props.btnStyle === BtnStyles.Secondary || props.btnStyle === BtnStyles.Tertiary) {
        isOutlined.value = true;
      }
    };

    const getBtnStyle = () => {
      return props.btnStyle;
    };

    return {
      isDepressed,
      isOutlined,

      getBtnStyle
    };
  }
});
