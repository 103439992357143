var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "SHeaderSection",
    { attrs: { title: _vm.teamRequest.name } },
    [
      _c(
        "v-container",
        { staticClass: "px-0 pt-10 pb-0 mx-0", style: { minWidth: "100%" } },
        [
          _vm.isUserClient
            ? _c(
                "v-row",
                { staticClass: "mb-6", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("HeaderTeamRequestStatus", {
                        attrs: {
                          teamRequest: _vm.teamRequest,
                          teamRequestStatus: _vm.extendedTeamRequestStatus,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-4", attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", [
                        _c("span", {
                          staticClass: "text-h4 text--text text--base",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("teamRequestDetail.stemdoerList.title")
                            ),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mb-6", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("StemdoerList", {
                            attrs: {
                              stemdoerRates: _vm.teamRequest.stemdoerRates,
                              teamRequestStatus: _vm.extendedTeamRequestStatus,
                              onStemdoerRateChange: _vm.updateStemdoerRate,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 ml-6 mb-4", attrs: { cols: "5" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-4", attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", [
                        _c("span", {
                          staticClass: "text-h4 text--text text--base",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("teamRequestDetail.overview.details")
                            ),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("TeamRequestOverview", {
                            attrs: { teamRequest: _vm.teamRequest },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-6", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("ButtonsGroup", {
                            attrs: {
                              teamRequest: _vm.teamRequest,
                              teamRequestStatus: _vm.extendedTeamRequestStatus,
                              refreshTeamRequest: _vm.refreshTeamRequest,
                              onSuccess: _vm.handleSuccess,
                              onError: _vm.handleError,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          isOpen: _vm.isAvailableStemdoersModalOpen,
          title: _vm.$t("managerTeamDetail.modalStemoderNoAvailable.title"),
          maxWidth: "500px",
          closeModal: _vm.closeAvailableStemdoersModal,
          customProps: { bodyPaddingY: 0, fullWidthFooter: true },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "span",
                  { staticClass: "text-body-1 text--text base--base" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "managerTeamDetail.modalStemoderNoAvailable.message"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "v-container",
                  { staticClass: "p-0" },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pr-2", attrs: { cols: "6" } },
                          [
                            _c("sButton", {
                              attrs: {
                                btnStyle: "secondary",
                                sText:
                                  "managerTeamDetail.modalStemoderNoAvailable.buttonContinue",
                                action: _vm.handleContinueAction,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "pl-2", attrs: { cols: "6" } },
                          [
                            _c("sButton", {
                              attrs: {
                                btnStyle: "primary",
                                sText:
                                  "managerTeamDetail.modalStemoderNoAvailable.buttonExplore",
                                action: _vm.gotoExplore,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("SPopUp", {
        staticClass: "popUp",
        attrs: {
          isOpen: _vm.errorPopUp,
          sText: _vm.$t("label.teams.selected.errorPopUp"),
          isValid: !_vm.errorPopUp,
        },
        on: { close: _vm.handleClosePopUp },
      }),
      _vm._v(" "),
      _c("SPopUp", {
        staticClass: "popUp",
        attrs: {
          isOpen: _vm.successPopUp,
          sText: _vm.successMessage,
          isValid: _vm.successPopUp,
        },
        on: { close: _vm.handleClosePopUp },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }