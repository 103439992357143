import { defineComponent, inject, PropType, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { TeamRequest } from '@/shared/model/team-request.model';
import { ButtonGroupModals } from '../../../buttonsGroup';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true,
    },
    refreshTeamRequest: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onOpenModal: {
      type: Function,
      required: true,
    },
  },
  components: {
    SButton,
    SModalCentered,
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService');

    const sendProposal = () => {
      if (props.teamRequest.status === RequestStatus.PENDING) {
        teamRequestService.validateAndPartialUpdate(props.teamRequest).then(() => {
          props.onOpenModal(ButtonGroupModals.NEW_PROPOSAL);
          props.refreshTeamRequest();
        });
      } else if (props.teamRequest.status === RequestStatus.VALIDATED) {
        teamRequestService.partialUpdate(props.teamRequest).then(() => {
          props.onOpenModal(ButtonGroupModals.NEW_PROPOSAL);
          props.refreshTeamRequest();
        });
      }
    };

    return {
      sendProposal,
    };
  },
});
