var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "h-100", staticStyle: { "background-color": "white" } },
    [
      _c("v-img", {
        attrs: {
          src: `/content/svgs/coming-soon/${_setup.currentLanguage}/teams.svg`,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }