// import Error from '@/sections/error/Error.vue';
import Component from 'vue-class-component';

const ErrorPage = () => import('@/pages/error/ErrorPage.vue');

export default [
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: ErrorPage,
    meta: { error403: true },
  },
  {
    path: '/uuups',
    name: 'ServerError',
    component: ErrorPage,
    meta: { error500: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundError',
    component: ErrorPage,
    meta: { error404: true },
  },
];
