import { defineComponent, inject, PropType, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { ButtonGroupModals } from '../../../buttonsGroup';
import { TeamRequest } from '@/shared/model/team-request.model';
import { useStore } from '@/plugins/vuex';
import { useRouter } from '@/plugins/router';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true
    },
    onOpenModal: {
      type: Function,
      required: true
    }
  },
  components: {
    SButton,
    SModalCentered
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const openAcceptOnlyAvailablesStemdoersModal = () => {
      props.onOpenModal(ButtonGroupModals.SAVED_TO_PENDING_TR_ONLY_AVAILABLES);
    };

    const openSaveTRModal = () => {
      const stemdoers = store.getters['cartStore/cartItems'];
      if (stemdoers && stemdoers.length > 0) {
        props.onOpenModal(ButtonGroupModals.CHECK_CART_SAVE_TR);
      } else {
        gotoExplore();
      }
    };

    const gotoExplore = () => {
      router.push({
        name: 'exploreClient',
        query: { tr: props.teamRequest.id }
      });
    };

    return {
      openSaveTRModal,
      openAcceptOnlyAvailablesStemdoersModal
    };
  }
});
