import { defineComponent, PropType, ref, watch } from 'vue';
import { useI18N } from '@/plugins/i18n';
import { TeamRequest } from '@/shared/model/team-request.model';
import { ExtendedTeamRequestStatus } from '../enum/extendedTeamRequestStatus';
import SLabel from '@/components/stemdo-components/s-label/SLabel.vue';
import { LabelStyles } from '@/components/stemdo-components/s-label/sLabel';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object as () => TeamRequest,
      required: true,
    },
    teamRequestStatus: {
      type: String as PropType<ExtendedTeamRequestStatus>,
    },
  },
  components: {
    SLabel,
  },
  setup(props) {
    const i18n = useI18N();
    const statusLabelText = ref(undefined);
    const statusInfoText = ref(undefined);
    const labelStyle = ref<LabelStyles>(undefined);

    const updateStatusTexts = () => {
      switch (props.teamRequestStatus) {
        case ExtendedTeamRequestStatus.CLIENT_AND_CANCELLED:
          statusLabelText.value = i18n.t('teamRequestDetail.statusHeader.status.client.cancelled.title').toString();
          statusInfoText.value = i18n.t('teamRequestDetail.statusHeader.status.client.cancelled.info').toString();
          labelStyle.value = LabelStyles.Quaternary;
          break;
        case ExtendedTeamRequestStatus.CLIENT_AND_SAVED:
          statusLabelText.value = i18n.t('teamRequestDetail.statusHeader.status.client.saved.title').toString();
          labelStyle.value = LabelStyles.Secondary;
          break;
        case ExtendedTeamRequestStatus.CLIENT_AND_PENDING:
          statusLabelText.value = i18n.t('teamRequestDetail.statusHeader.status.client.pending.title').toString();
          statusInfoText.value = i18n.t('teamRequestDetail.statusHeader.status.client.pending.info').toString();
          labelStyle.value = LabelStyles.Quinary;
          break;
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_MODIFIED_BY_MANAGER:
          statusLabelText.value = i18n.t('teamRequestDetail.statusHeader.status.client.newProposal.modifiedByManager.title').toString();
          statusInfoText.value = i18n.t('teamRequestDetail.statusHeader.status.client.newProposal.modifiedByManager.info').toString();
          labelStyle.value = LabelStyles.Quinary;
          break;
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_MODIFIED_BY_MANAGER:
          statusLabelText.value = i18n.t('teamRequestDetail.statusHeader.status.client.newProposal.title').toString();
          statusInfoText.value = i18n.t('teamRequestDetail.statusHeader.status.client.newProposal.info').toString();
          labelStyle.value = LabelStyles.Tertiary;
          break;
        case ExtendedTeamRequestStatus.CLIENT_AND_ON_GOING:
          statusLabelText.value = i18n.t('teamRequestDetail.statusHeader.status.client.approvedTeamRequest.title').toString();
          const teamName = props.teamRequest.name;
          const startDate = i18n.d(new Date(props.teamRequest.initDate));
          statusInfoText.value = i18n
            .t('teamRequestDetail.statusHeader.status.client.approvedTeamRequest.info', { teamName, startDate })
            .toString();
          labelStyle.value = LabelStyles.Tertiary;
          break;
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE:
          statusLabelText.value = i18n.t('teamRequestDetail.statusHeader.status.client.unavailableCandidates.title').toString();
          statusInfoText.value = i18n.t('teamRequestDetail.statusHeader.status.client.unavailableCandidates.info').toString();
          labelStyle.value = LabelStyles.Quinary;
          break;
        case ExtendedTeamRequestStatus.CLIENT_AND_SAVED_AND_NOT_ALL_STEMDOERS_AVAILABLE:
          statusLabelText.value = i18n.t('teamRequestDetail.statusHeader.status.client.savedAndNotAllStemdoersAvailable.title').toString();
          labelStyle.value = LabelStyles.Secondary;
          break;
        default:
          statusLabelText.value = undefined;
          statusInfoText.value = undefined;
          break;
      }
    };

    watch(
      () => [props.teamRequestStatus, i18n.locale],
      () => {
        updateStatusTexts();
      },
      { immediate: true }
    );

    return { statusLabelText, statusInfoText, labelStyle };
  },
});
