var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "p-0" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c(_vm.currentComponent, {
                tag: "component",
                attrs: {
                  teamRequest: _vm.mutableTeamRequest,
                  onOpenModal: _vm.openModal,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ConfirmationModal", {
        attrs: {
          title: _vm.$t("checkout.modal.confirmation.title"),
          isOpen: _vm.isConfirmationModalOpen,
          onClose: _vm.closeModal,
        },
      }),
      _vm._v(" "),
      _c("CheckoutConfirmationModal", {
        attrs: {
          title: _vm.$t("checkout.modal.manager.validated.title"),
          isOpen: _vm.isManagerCheckoutConfirmationModalOpen,
          onClose: _vm.closeModal,
          teamRequestId: _vm.mutableTeamRequest.id,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }