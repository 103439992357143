import { Component, Emit, Inject, Vue } from 'vue-property-decorator';
import btnStemdo from './button.component.vue';
import gsing from './gsing.component.vue';

@Component({
  name: 'SelectorComponents',
  props: {
    type: {
      type: String,
      required: true,
    },
    conf: {
      type: Object,
    },
  },
  components: {
    btnStemdo,
    gsing,
  },
})
export default class Stemdocomponents extends Vue {
  public componentType() {
    switch (this.$props.type) {
      case 'btnStemdo':
        return 'btnStemdo';
        break;
      case 'gsing':
        return 'gsing';
        break;
    }
  }
}
