var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("SModalCentered", {
    attrs: {
      title: _vm.$t("teamRequestDetail.modal.manager.cancelTeamRequest.title"),
      isOpen: _vm.isOpen,
      closeModal: _vm.handleClose,
      customProps: _vm.customModalProps,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "text-body-1 text--text text--lighten-1" },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "teamRequestDetail.modal.manager.cancelTeamRequest.body"
                    )
                  )
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("SButton", {
              attrs: {
                action: _vm.handleClose,
                sText: _vm.$t(
                  "teamRequestDetail.modal.manager.cancelTeamRequest.button"
                ),
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }