var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row align-center w-100" },
    [
      _c("div", { class: _vm.isActive || _vm.hover ? "vl-color" : "vl" }),
      _vm._v(" "),
      _c(
        "v-list-item-icon",
        { staticClass: "ml-2 mr-4" },
        [
          _c("v-img", {
            attrs: {
              width: 24,
              src:
                "/content/svgs/" +
                (_vm.item.disable
                  ? _vm.item.iconDisableName
                  : _vm.isActive || _vm.hover
                  ? _vm.item.iconActiveName
                  : _vm.item.iconName) +
                ".svg",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-content",
        { staticClass: "py-1" },
        [
          _c(
            "v-list-item-title",
            {
              class: [
                "py-1",
                _vm.item.disable
                  ? "text-body-1 text--text text--lighten-1"
                  : "",
                _vm.hover ? "hover-item-content__title" : "",
              ],
            },
            [_vm._v("\n      " + _vm._s(_vm.$t(_vm.item.title)) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.disable
        ? _c(
            "v-list-item-icon",
            [
              _c("v-img", {
                attrs: { width: 24, src: "/content/svgs/lock.svg" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.comingSoon === true
        ? _c(
            "v-list-item-icon",
            [_c("BadgetSoon", { attrs: { item: _vm.item } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.isTest
        ? _c(
            "v-list-item-icon",
            { staticClass: "mx-0 my-auto" },
            [_c("BadgetTest", { attrs: { item: _vm.item } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }